import { Col, Row, Text, Button, WalletBalanceRenderer, Table, showPopupMessage, useRefState } from 'components';
import React, { useState, useEffect, useMemo } from 'react';
import { TCMSOrder } from 'type';
import { SCREEN, COLOR, shippingPrice, EUROPEAN_COUNTRIES, shippingPriceEurope, shippingPriceTheRestOfTheWorld, TAX_ONLY_RATE, DISCOUNT_RATE, SAMPLE_DISCOUNT } from 'const';
import { ActivityIndicator } from 'react-native';
import { usePaymentStore } from "store/Payment.Store";
import { Elements } from "@stripe/react-stripe-js";
import { TimeHelper, ValHelper } from 'helpers';
import { ColumnsType } from 'antd/es/table';
import { useNavFunc } from 'navigation';
import { notification } from 'antd';
import OrderTable from 'components/elements/table/OrderTablePro';
import apiClient from 'store/api-client';
import { useUserStore } from 'store/User.Store';

const PayBuyNowOrder = ({ stripeInvoice, draftInvoiceId, onClose }: {
  stripeInvoice: {
    lines: { data: any[] },
    metadata: any,
    total: number,
  },
  draftInvoiceId: string,
  onClose?: () => void
}) => {
  const { navigation } = useNavFunc();
  const { stripePromise, getStripeConfig, refreshBalance } = usePaymentStore();
  const [isPaying, getIsPaying, setIsPaying] = useRefState(false);
  const { user } = useUserStore();
  const skipPayment = user?.otherData?.skipPayment;

  useEffect(() => {
    getStripeConfig();
    refreshBalance();
  }, []);

  const totalPrice = stripeInvoice?.total / 100;

  const lineItemColumns: ColumnsType<any> = [
    {
      title: 'Order No',
      key: 'orderNo',
      dataIndex: 'orderNo',
      align: 'center'
    },
    {
      title: 'Product Price',
      dataIndex: 'products',
      key: 'products',
      render: (amount) => "£" + (Number(amount) / 100).toFixed(2),
      align: 'center'
    },
    {
      title: 'Shipping',
      dataIndex: 'shippingfee',
      key: 'shippingfee',
      render: (amount) => "£" + (Number(amount) / 100).toFixed(2),
      align: 'center'
    },
    {
      title: 'VAT 20%',
      dataIndex: 'vat',
      key: 'vat',
      render: (amount) => "£" + (Number(amount || 0) / 100).toFixed(2),
      align: 'center'
    },
  ]

  const orders = useMemo(() => {
    const arr = [];
    const orderNo = `#${stripeInvoice?.metadata?.orderNumber}`

    const shipItem = stripeInvoice?.lines?.data?.find(i => i.description?.includes("Shipping fee"));
    const taxItem = stripeInvoice?.lines?.data?.find(i => i.description?.includes("VAT "));

    const productsOnly = stripeInvoice?.total - (shipItem?.amount || 0) - (taxItem?.amount || 0);
    arr.push({
      orderNo,
      products: productsOnly,
      shippingfee: shipItem?.amount,
      vat: taxItem?.amount,
    })
    return arr
  }, [stripeInvoice])

  const onAcceptPayment = async (walletBalance) => {
    if (typeof walletBalance === 'number' && walletBalance < totalPrice) return;
    try {
      if (isPaying || getIsPaying()) return;
      setIsPaying(true);
      const res = await apiClient.Api.Payment.chargeByDraftInvoice({
        draftInvoiceIds: [draftInvoiceId],
      })
      if (res.data.success) {
        onClose?.();
        notification.success({
          message: 'Order success',
          description: 'You have successfully paid the order. Please await for admin approval.',
        });
      } else {
        throw new Error(res.data.error);
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',
        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
      // alert(err);
      console.log(err);
    } finally {
      setIsPaying(false);
    }
  }

  useEffect(() => {
    if (skipPayment) {
      onAcceptPayment(undefined)
    }
  }, [skipPayment]);

  if (!stripePromise) return null;

  return (
    <Elements stripe={stripePromise} >
      <WalletBalanceRenderer>
        {({ balance }) => {

          const walletBalance = (balance?.balance * -1 || 0) / 100

          const renderBalance = () => {
            return (
              <Text bold>£{ValHelper.formatBalance(walletBalance)} {String(balance?.currency || "GBP").toUpperCase()}</Text>
            )
          }

          return (
            <Col flex1 mv2>
              <Text mh2 bold mb1 fontSize={18} textAlign={"center"}>Pay and proceed</Text>
              <Text mh2 bold mb2 fontSize={18} textAlign={"center"}>Balance: {renderBalance()}</Text>
              {/* DEFAULT TO RM48 FOR NOW */}
              {/* <SelectShippingService onChange={setShipping} value={shipping} /> */}
              <Col>
                <Text mh2 mv0>
                  Accepting these jobs will take funds from your wallet. Once paid, these orders cannot be amended. Bottled Goose will review these orders before they go into production.
                </Text>
                <Col mv1>
                  <OrderTable
                    style={{
                      maxHeight: 320,
                      overflowY: "auto",
                    }}
                    data={orders}
                    className="payList"
                    rowClassName="stripeInvoiceTable"
                    columns={lineItemColumns}
                    rowKey={record => `${record.id}`}
                    sticky={true}
                  />
                </Col>
                <Text mv0 center>Total: <Text bold fontSize={20}>£{ValHelper.formatMoney(totalPrice)} GBP</Text></Text>
                <Text mv0 center bold>Do you wish to continue?</Text>
              </Col>
              <Row mh2>
                <Col flex1 marginRight={10}>
                  <Col>
                    <Button
                      outline
                      height={30}
                      width='100%'
                      text='Cancel'
                      onPress={onClose}
                    />
                  </Col>
                </Col>
                {(walletBalance < totalPrice || walletBalance === 0) && (
                  <Col flex1 marginRight={10}>
                    <Col>
                      <Button
                        outline
                        height={30}
                        width='100%'
                        text='Add balance'
                        onPress={async () => {
                          onClose?.();
                          navigation.navigate(SCREEN.Settings, { tabId: 'billing' });
                        }}
                      />
                    </Col>
                  </Col>
                )}
                <Col flex1>
                  <Col>
                    <Button
                      opacity={walletBalance < totalPrice ? 0.5 : 1}
                      solid
                      isLoading={isPaying}
                      height={30}
                      width='100%'
                      text='Accept'
                      onPress={TimeHelper.throttle(() => onAcceptPayment(walletBalance))}
                    />
                  </Col>
                </Col>
              </Row>
            </Col>
          )
        }}
      </WalletBalanceRenderer>
    </Elements>

  )
}

export default PayBuyNowOrder;
