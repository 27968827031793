import React, { forwardRef, useImperativeHandle } from "react";
import { Col } from "components";

interface IProps { }

const CloneMeSettings = (props: IProps, ref) => {
  useImperativeHandle(ref, () => ({
    getData: () => {
    },
  }));

  return (
    <Col flex1>

    </Col>
  );
};

export default forwardRef(CloneMeSettings);
