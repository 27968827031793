import { Col, IColProps, Row, Text } from "components/base";
import React, { ReactNode } from "react";
import BgIcon from "../BgIcon";
import { Input, InputProps, Space } from "antd";
import { COLORS } from "const";

interface Props extends IColProps {
  title?: string,
  icon?: string,
  value?: string,
  error?: string,
  isRequired?: boolean,
  onChangeText?: (txt: string) => void,
  inputProps?: InputProps,
  right?: ReactNode,
}

const InputField = (props: Props) => {
  const { title, icon, value, onChangeText, inputProps, error, isRequired, right, ...restProps } = props;
  return (
    <Col {...restProps}>
      {!!title && (
        <Row marginBottom={8} alignItems="center">
          <Text fontWeight="500" fontSize={14}>{title}</Text>
          {!!icon && (
            <Col marginLeft={8}>
              <BgIcon name={icon} size={14} />
            </Col>
          )}
          {isRequired && (
            <Text color={COLORS.RED} fontSize={18} marginLeft={2}>*</Text>
          )}
        </Row>
      )}
      {!right ? (
        <Input
          size="large"
          value={value}
          onChange={(e) => onChangeText(e.target.value)}
          status={!!error ? "error" : undefined}
          {...inputProps}
        />
      ) : (
        <Space.Compact style={{ width: '100%' }}>
          <Input
            size="large"
            value={value}
            onChange={(e) => onChangeText(e.target.value)}
            status={!!error ? "error" : undefined}
            {...inputProps}
          />
          {right}
        </Space.Compact>
      )}
      {!!error && (
        <Text fontSize={12} marginTop={2} color={COLORS.RED}>{error}</Text>
      )}
    </Col>
  )
}

export default InputField;
