import Store from 'store';
import { COLOR, SCREEN } from 'const';
////////////////////////////////////////////////
import React, { useState, useRef, useEffect } from 'react';
import { BgIcon, useUIState, Col, Row, Text, showPopupMessage } from 'components';
import {
  ScrollView, View, TouchableOpacity, StyleSheet, Platform,
  useWindowDimensions, TextInput, Image,
  ActivityIndicator,
} from 'react-native';
import { useNavFunc } from 'navigation';
import { ValHelper } from 'helpers';
import { dataFacts } from 'const/facts';
import { SVG } from 'assets';
import { FlatList } from 'react-native-gesture-handler';
import { ASSETS } from 'assets';
import GeeseFactsRandom from 'components/widgets/facts/GeeseFactsRandom';
import { IScreen } from 'type';
import { auth, googleProvider, facebookProvider } from 'config/firebase';
import { signInWithPopup } from 'firebase/auth';

////////////////////////////////////////////////

const Login: IScreen = () => {
  const { navigate, route } = useNavFunc();
  const { width } = useWindowDimensions();

  const { redirect }: any = route.params || {};
  const UserStore = Store.useUserStore();
  const [{ loading }, setUI] = useUIState();

  const minWidth = 900;
  const isResizeLayout = width > minWidth;
  const isMobile = width <= minWidth;
  const resizeFont = (width: number, minWidth: number) => {
    if (width > 1000) return 0;
    if (minWidth <= width) return 2;
    if (width < minWidth && width >= 550) return 0;
    if (width < 550) return 2;
  }
  /* Logic */
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const isLoginEnabled =
    email.length > 0
    && password.length > 0
    ;
  const [openEyePassWord, setOpenEyePassword] = useState(true);

  /* Validate */
  const [errorMessageEmail, setErrorMessageEmail] = useState('');
  /* Login */

  const [erroLoginMessage, setErroLoginMessage] = useState('');
  const handleLogin = async () => {
    setUI({ loading: true });
    try {
      const res = await Store.Client.Api.User.login({
        email,
        password,
      });
      if (res.data.success) {
        const token = res.data.data?.token;
        Store.Client.setToken(token);
        UserStore.set({
          token,
          user: res.data.data?.publicInfo,
        });
        if (redirect) {
          navigate(redirect);
        } else {
          navigate(SCREEN.Home);
        }
      } else {
        setErroLoginMessage('Error: User does not exist');
      }
    } catch (err) {

    }
    setUI({ loading: false });

  }
  const [socialLoading, setSocialLoading] = useState<{
    google: boolean;
    facebook: boolean;
  }>({
    google: false,
    facebook: false,
  });

  const handleSocialLogin = async (providerType: 'google' | 'facebook') => {
    setSocialLoading(prev => ({ ...prev, [providerType]: true }));
    try {
      const provider = providerType === 'google' ? googleProvider : facebookProvider;

      const result = await signInWithPopup(auth, provider);
      const idToken = await result.user?.getIdToken();

      if (!idToken) {
        throw new Error('Failed to get ID token');
      }

      const res = await Store.Client.Api.User.socialAuth({
        idToken,
        provider: providerType,
      });

      if (res.data.success) {
        const token = res.data.data?.token;
        Store.Client.setToken(token);
        UserStore.set({
          token,
          user: res.data.data?.publicInfo,
        });
        if (redirect) {
          navigate(redirect);
        } else {
          navigate(SCREEN.Home);
        }
      }
    } catch (error) {
      console.error('Social login error:', error);
      showPopupMessage({
        title: '',
        content: 'Social login failed. Please try again.',
        buttonOkText: 'OK',
        typeHighlight: 'danger',
        contentHighlight: 'Error'
      });
    } finally {
      setSocialLoading(prev => ({ ...prev, [providerType]: false }));
    }
  };

  /* Show screen */

  const renderFact = () => {
    return (
      <Col
        width={isResizeLayout ? '50%' : '100%'}
        justifyContent={'center'}
        alignItems={isResizeLayout ? 'flex-end' : 'center'}
        paddingLeft={isResizeLayout ? 30 : 30}
        paddingRight={isResizeLayout ? 0 : 30}
        height={isResizeLayout ? '100%' : ''}
      >
        <Col
          width={'100%'}
          height={'100%'}
          backgroundColor={'#FFFFFFE5'}
          opacity={0.9}
          middle
          borderRadius={16}
          padding={isResizeLayout ? 16 : 5}
        >
          <GeeseFactsRandom />
        </Col>
      </Col>
    )
  }

  const renderForm = () => {
    return (
      <Col
        width={isResizeLayout ? '50%' : '100%'}
        middle
        height={isResizeLayout ? '100%' : ''}
      >
        <Image
          source={ASSETS.BG_SMALL_BLUE}
          style={{
            width: 100, display: isResizeLayout ? '' : 'none',
            height: 120, borderRadius: 10, alignSelf: 'center', marginRight: 10
          }}
          resizeMode="contain"
        />

        <Col
          maxWidth={750}
          justifyContent={'center'}
          paddingHorizontal={30}
          width={'100%'}
        >
          <Col
            marginTop={20}
            marginBottom={isResizeLayout ? 50 : 16}
          >
            <Row
              flexWrap={'wrap'}
              justifyContent={'center'}
            >
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={600}
                fontSize={32 - resizeFont(width, minWidth)}
                lineHeight={40}
                marginBottom={16}
                color={'#101840'}
                textAlign={'center'}
              >Welcome to </Text>
              <Image
                source={ASSETS.BG_SMALL_BLUE}
                style={{
                  width: 40, height: 52, borderRadius: 10,
                  display: isResizeLayout ? 'none' : '',
                  alignSelf: 'center', marginRight: 10,
                }}
                resizeMode="contain"
              />
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={600}
                fontSize={32 - resizeFont(width, minWidth)}
                lineHeight={40}
                marginBottom={16}
                color={'#101840'}
                textAlign={'center'}
              >Bottled Goose</Text>
            </Row>
            <Text
              fontFamily={'Inter, sans-serif'}
              fontWeight={600}
              fontSize={16 - resizeFont(width, minWidth)}
              lineHeight={24}
              color={'#8692A6'}
              textAlign={'center'}
            >Please input your email and password to login</Text>

          </Col>
          <Col
          >
            <Col
              marginBottom={16}
            >
              <Text
                fontFamily={'Inter, sans-serif'}
                fontWeight={600}
                fontSize={14 - resizeFont(width, minWidth)}
                lineHeight={21}
                color={'#101840'}
                marginBottom={8}
              >Email address</Text>
              <TextInput
                style={{
                  fontFamily: 'Inter, sans-serif',
                  fontWeight: 400,
                  fontSize: 16 - resizeFont(width, minWidth),
                  lineHeight: 24,
                  height: 52,
                  borderWidth: 1,
                  borderRadius: 5,
                  borderColor: '#696F8C',
                  paddingVertical: 14,
                  paddingHorizontal: 16,
                  outline: 'none',
                  flex: 1,
                }}
                placeholder='Enter your email address'
                placeholderTextColor={'#696F8C'}
                onChangeText={(text) => setEmail(text)}
                value={email}
                nativeID='enter-email'

              ></TextInput>
            </Col>
            {errorMessageEmail && <Text
              style={{
                color: 'red',
                marginTop: 10,
                fontSize: 14,
              }}>
              {errorMessageEmail}</Text>}

            <Col flex1
              marginBottom={16}
            >
              <Col
              >
                <Text
                  fontFamily={'Inter, sans-serif'}
                  fontWeight={600}
                  fontSize={14 - resizeFont(width, minWidth)}
                  lineHeight={21}
                  color={'#101840'}
                  marginBottom={8}
                >Password</Text>
                <Row>
                  <TextInput
                    style={{
                      fontFamily: 'Inter, sans-serif',
                      fontWeight: 400,
                      fontSize: 16 - resizeFont(width, minWidth),
                      lineHeight: 24,
                      height: 52,
                      borderWidth: 1,
                      borderRadius: 5,
                      borderColor: '#696F8C',
                      paddingVertical: 14,
                      paddingHorizontal: 16,
                      outline: 'none',
                      flex: 1,
                    }}
                    placeholder='Enter your password'
                    placeholderTextColor={'#696F8C'}
                    secureTextEntry={openEyePassWord}
                    onChangeText={(text) => setPassword(text)}
                    value={password}
                    nativeID='enter-password'
                    onSubmitEditing={handleLogin}
                  ></TextInput>
                  <Col
                    absolute
                    right={20}
                    style={{ outline: 'none', }}
                    onPress={() => { setOpenEyePassword(!openEyePassWord) }}
                  >{openEyePassWord ? <SVG.EyeOpen /> : <SVG.EyeClose />}</Col>
                </Row>
              </Col>
              <Row>

                <Text
                  marginTop={2}
                  flex={1}
                  fontFamily={'Inter, sans-serif'}
                  fontWeight={400}
                  fontSize={16 - resizeFont(width, minWidth)}
                  lineHeight={24}
                  color={'red'}
                  textAlign={'left'}
                >
                  {erroLoginMessage}
                </Text>
                <Text
                  marginTop={2}
                  flex={1}
                  fontFamily={'Inter, sans-serif'}
                  fontWeight={500}
                  fontSize={14 - resizeFont(width, minWidth)}
                  lineHeight={24}
                  color={'#3366FF'}
                  textAlign={'right'}
                  // onPress={() => { window.location.href = 'https://bg-dev.bottledgoose.co.uk/forgot-password' }}
                  onPress={() => navigate('ForgotPassword')}
                >
                  Forgot password?
                </Text>
              </Row>
            </Col>

            <Col marginBottom={16}>
              <Col
                middle
                paddingVertical={8}
                paddingHorizontal={16}
                marginBottom={20}
                borderRadius={4}
                backgroundColor={'#223363'}
                opacity={isLoginEnabled ? 1 : 0.7}
                // @ts-ignore
                style={{ outline: 'none', }}
                onPress={() => {
                  // validateEmail();
                  handleLogin();
                }}
                disabled={!isLoginEnabled}
              >
                <Text
                  fontFamily={'Inter, sans-serif'}
                  fontWeight={600}
                  fontSize={16 - resizeFont(width, minWidth)}
                  lineHeight={16}
                  color={'#FFF'}
                >
                  {loading ? 'Logging in..' : 'Log in'}
                </Text>
              </Col>
              {!['bg-production.bottledgoose.co.uk', 'bg-production.personify.tech'].includes(window.location.host) && (
                <>
                  <Row marginBottom={10}>
                    <Col flex6 height={1} backgroundColor={'#686677'}></Col>
                    <Text
                      fontFamily={'Inter, sans-serif'}
                      fontWeight={600}
                      fontSize={12 - resizeFont(width, minWidth)}
                      lineHeight={16}
                      color={'#101840'}
                      flex={1}
                      textAlign={'center'}
                    >
                      Or
                    </Text>
                    <Col flex6 height={1} backgroundColor={'#686677'}></Col>
                  </Row>
                  <Row marginBottom={20} justifyContent="center">
                    <Col
                      middle
                      paddingVertical={6}
                      paddingHorizontal={12}
                      marginRight={8}
                      borderRadius={4}
                      backgroundColor={'#FFFFFF'}
                      onPress={() => !socialLoading.google && handleSocialLogin('google')}
                      opacity={socialLoading.google ? 0.7 : 1}
                    >
                      <Row middle>
                        {socialLoading.google ? (
                          <Col width={24} height={24} justifyContent="center" alignItems="center">
                            <ActivityIndicator size="small" color="#4285F4" />
                          </Col>
                        ) : (
                          <SVG.GoogleIcon width={24} height={24} />
                        )}
                        <Text
                          color="#000"
                          marginLeft={6}
                          fontFamily={'Inter, sans-serif'}
                          fontWeight={500}
                          fontSize={13}
                        >
                          {socialLoading.google ? 'Signing in...' : 'Sign in with Google'}
                        </Text>
                      </Row>
                    </Col>

                    <Col
                      middle
                      paddingVertical={6}
                      paddingHorizontal={12}
                      borderRadius={4}
                      backgroundColor={'#FFFFFF'}
                      onPress={() => !socialLoading.facebook && handleSocialLogin('facebook')}
                      opacity={socialLoading.facebook ? 0.7 : 1}
                    >
                      <Row middle>
                        {socialLoading.facebook ? (
                          <Col width={24} height={24} justifyContent="center" alignItems="center">
                            <ActivityIndicator size="small" color="#3b5998" />
                          </Col>
                        ) : (
                          <SVG.FacebookIcon width={24} height={24} />
                        )}
                        <Text
                          color="#000"
                          marginLeft={6}
                          fontFamily={'Inter, sans-serif'}
                          fontWeight={500}
                          fontSize={13}
                        >
                          {socialLoading.facebook ? 'Signing in...' : 'Sign in with Facebook'}
                        </Text>
                      </Row>
                    </Col>
                  </Row>
                </>
              )}
              <Col
              >
                <Text
                  fontFamily={'Inter, sans-serif'}
                  fontWeight={400}
                  fontSize={16 - resizeFont(width, minWidth)}
                  lineHeight={24}
                  color={'#101840'}
                >
                  Have an account?
                  <Text
                    fontFamily={'Inter, sans-serif'}
                    fontWeight={500}
                    fontSize={14 - resizeFont(width, minWidth)}
                    lineHeight={24}
                    color={'#3366FF'}
                    marginLeft={8}
                    onPress={() => navigate('ResellerRegister')}
                  >
                    Reseller Register
                  </Text>
                </Text>
              </Col>
            </Col>
          </Col>
        </Col>
      </Col>
    )
  }

  const renderMobile = () => {
    return (
      <ScrollView style={{ height: 500, backgroundColor: COLOR.EXTENDED_N300 }}>
        {renderForm()}
        {renderFact()}
        <Col height={50} />
      </ScrollView>
    )
  };

  return isMobile ? renderMobile() : (
    <Col
      flex1
      backgroundColor={COLOR.EXTENDED_N300}
    >
      <ScrollView
        showsVerticalScrollIndicator={false}
        contentContainerStyle={{
          flex: 1,
        }}
      >
        <Col
          flex1
          middle
          paddingBottom={isResizeLayout ? 0 : 50}
        >
          <Row
            flexWrap={'wrap-reverse'}
            flex1
            marginVertical={isResizeLayout ? 30 : 10}
            width={'100%'}
          >
            {renderFact()}
            {renderForm()}
          </Row>
        </Col>
      </ScrollView>
    </Col>
  )
}

Login.routeInfo = {
  title: 'Login',
  path: '/login',
}

export default Login;