import React, { forwardRef, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { BgIcon, Col, Row, showPopupMessage, Table, Text } from "components";
import { ValHelper } from "helpers";
import { ColumnsType } from "antd/es/table";
import apiClient, { TSurveyAnwser, TSurveyAnwserData, TSurveyFormModel } from "store/api-client";
import { debounce } from "lodash";
import { FORM_TYPE } from "const/survey";
import { IGetSurveyAnwserRequestQuery } from "store/api-client/api/SurveyAPI";
import { COLORS } from "const";
import { Pagination, Input } from "antd";

interface IProps { }

const PAGE_SIZE = 20;

const SurveyAnwserSettings = (props: IProps, ref) => {
  const [page, setPage] = useState(1);
  const [searchKeyword, setSearchKeyword] = useState('');
  const searchKeywordRef = useRef(searchKeyword);
  const [total, setTotal] = useState(0);
  const sorter = useRef<{ [key: string]: any }>();
  const filter = useRef<{ [key: string]: any }>();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [formModel, setFormModel] = useState<TSurveyFormModel>();

  const getData = useCallback(async (p = 1) => {
    setIsLoading(true);
    const params: IGetSurveyAnwserRequestQuery = {
      limit: PAGE_SIZE,
      offset: (p - 1) * PAGE_SIZE,
      keyword: searchKeywordRef.current,
      sortField: '',
      sortOrder: '',
      type: FORM_TYPE.INITIAL_QUESTION,
    }
    if (sorter.current?.order) {
      params.sortField = sorter.current.columnKey
      params.sortOrder = sorter.current.order === "descend" ? "DESC" : "ASC"
    }
    try {
      const res = await apiClient.Api.Survey.getSurveyAnwser(params);
      setData(res?.data?.data?.rows || []);
      setTotal(res?.data?.data?.total || 0);
    } catch (error) {
      showPopupMessage({
        title: '',
        content: String(error?.message || 'Something went wrong'),
        buttonOkText: 'OK',

        typeHighlight: 'danger',
        contentHighlight: 'Error'
      });
    } finally {
      setIsLoading(false);
    }
  }, []);

  const debouncedGetData = useCallback(
    debounce(getData, 300),
    []
  );

  useEffect(() => {
    apiClient.Api.Survey.getSurveyForm({
      type: FORM_TYPE.INITIAL_QUESTION,
    }).then((res) => {
      if (res?.data?.data?.data) {
        setFormModel(JSON.parse(res?.data?.data?.data));
      }
    })
  }, [])

  useEffect(() => {
    debouncedGetData(page);
  }, [page]);

  const onChangeFilter = useCallback((_, _filter, _sorter) => {
    sorter.current = _sorter;
    filter.current = _filter;
    setPage(1);
    debouncedGetData(1);
  }, []);

  console.log("xxxxx", formModel)

  const fieldColumns = useMemo(() => {
    if (!formModel) return []
    const questions = formModel?.pages?.flatMap(page => page.elements);
    return questions.map((i, idx) => ({
      title: i.name,
      key: `field${idx + 1}`,
      dataIndex: `field${idx + 1}`,
    }))
  }, [formModel])

  const columns: ColumnsType<TSurveyAnwserData> = [
    {
      title: 'Reseller',
      key: 'reseller',
      render: (text, record: TSurveyAnwserData) => {
        return (
          <Col>
            <Text mb0>
              {record.user?.firstName} {record.user?.lastName}
            </Text>
            <Text caption>{record.user?.email}</Text>
          </Col>
        )
      }
    },
  ].concat(fieldColumns as any);

  return (
    <Col flex1>
      <Row mb1>
        <Input
          value={searchKeyword}
          size="large"
          placeholder="Search by name, email"
          prefix={(
            <Col marginBottom={-4}>
              <BgIcon name="search" size={18} color={COLORS.ICON_MUTED} />
            </Col>
          )}
          onChange={(e) => {
            setSearchKeyword(e.target.value)
            searchKeywordRef.current = e.target.value;
          }}
          onPressEnter={() => {
            setPage(1);
            debouncedGetData(1);
          }}
        />
      </Row>
      <Table
        data={data}
        columns={columns}
        rowKey={val => `${val.id}`}
        onChange={onChangeFilter}
        loading={isLoading && page === 1}
      />
      <Pagination
        style={{ marginTop: 12 }}
        pageSize={PAGE_SIZE}
        current={page}
        total={total}
        onChange={setPage}
        showSizeChanger={false}
      />
    </Col>
  );
};

export default forwardRef(SurveyAnwserSettings);
