import { Col, IColProps } from "components/base";
import React, { useState } from "react";
import { ImageStyle, LayoutChangeEvent } from "react-native";
import { Image, StyleSheet } from "react-native";
import { Image as AntImage } from "antd";
import BgIcon from "../BgIcon";
import TouchField from "../button/TouchField";
import { saveAs } from 'file-saver';

interface Props extends IColProps {
  uri: string,
  onPressRemove?: () => void,
  style?: ImageStyle,
  canRemove?: boolean,
  canPreview?: boolean,
  canDownload?: boolean,
  handleOnPress?: () => void,
}

const ImageItem = (props: Props) => {
  const {
    uri,
    onPressRemove,
    style,
    canRemove,
    canPreview = true,
    canDownload,
    handleOnPress,
    ...restProps
  } = props
  const [previewVisible, setPreviewVisible] = useState(false);
  const [imageWidth, setImageWidth] = useState(0);

  const handlePress = () => {
    if (canPreview) {
      setPreviewVisible(true);
    } else {
      handleOnPress?.();
    }
  }

  const onPressDownload = () => {
    const filename = uri.split('/').pop() || 'image.jpg';
    saveAs(uri, filename);
    // const response = await fetch(uri);
    // const blob = await response.blob();
    // const filename = uri.split('/').pop() || 'image.jpg';
    // saveAs(blob, filename);
  }

  const onImageLayout = (event: LayoutChangeEvent) => {
    setImageWidth(event.nativeEvent.layout.width);
  }

  return (
    // @ts-ignore
    <Col style={{ outline: "none" }} onPress={handlePress} {...restProps}>
      <Image
        source={{ uri }}
        style={[styles.imageItem, style]}
        onLayout={onImageLayout}
      />
      {canRemove &&
        <TouchField onPress={onPressRemove} absolute right={-8} top={-8} bgWhite borderRadius="50%">
          <BgIcon name="cross" size={16} />
        </TouchField>
      }
      {canPreview && (
        <AntImage
          style={{ display: 'none', width: 0, height: 0 }}
          src={uri}
          preview={{
            visible: previewVisible,
            src: uri,
            onVisibleChange: setPreviewVisible,
          }}
        />
      )}
      {canDownload && (
        <Col absolute bottom={8} alignSelf="center" width={imageWidth - 8 * 2} alignItems="flex-end">
          <TouchField onPress={onPressDownload} borderRadius="50%" padding={4}>
            <BgIcon name="download" size={16} />
          </TouchField>
        </Col>
      )}
    </Col>
  )
}

const styles = StyleSheet.create({
  imageItem: {
    width: 120,
    aspectRatio: 1,
    overflow: "hidden",
    borderWidth: 1,
    borderColor: "#D9D9D9",
  },
})

export default ImageItem;
