import Request from '../Request.utils'
export interface IListRequestQuery {
  customProduct?: number;
  page?: number;
  printOnDemand?: number;
  resellerId?: string;
  wholeSale?: number;
}
export interface IUpsertRequestBody {
  artboardUrl?: string;
  availableForResellerIds?: object;
  banner?: string;
  bluePrintImage?: string;
  category?: string;
  customProduct?: boolean;
  data?: any;
  description?: string;
  dpi?: number;
  dropletUrl?: string;
  editorHeight?: number;
  editorWidth?: number;
  estimatedLeadTime?: string;
  galleries?: string[];
  id?: string;
  image?: string;
  label?: string;
  material?: string;
  name?: string;
  originalPrice?: number;
  packPrices?: {
    discount?: number;
    price?: number;
    size?: number;
  }[];
  packagingDescription?: string;
  packagingImage?: string;
  physicalHeight?: number;
  physicalWidth?: number;
  previewData?: any;
  price?: number;
  printAreas?: {
    height?: number;
    left?: number;
    top?: number;
    width?: number;
  }[];
  printOnDemand?: boolean;
  printerIdentificatorCode?: string;
  productHeight?: number;
  productWidth?: number;
  secondLabel?: string | '' | null;
  tags?: string | '' | null;
  variations?: {
    prices?: {
      amount?: number;
      price?: number;
    }[];
    variant?: string;
  }[];
  volume?: string;
  wholeSale?: boolean;
}
export interface IRemoveRequestParams {
  id: string;
}
export interface IDetailRequestParams {
  id: string;
}
export interface IListBySKUsRequestBody {
  skus: string[];
}
export interface IOrderASampleRequestBody {
  customAddress?: {
    address1?: string;
    address2?: string;
    country?: string;
    county?: string;
    email?: string;
    first_name?: string;
    last_name?: string;
    phone?: string;
    town?: string;
    zip?: string;
  };
  designId: string;
  productId: string;
  productName: string;
  style?: string;
}


class ProductAPI {
  list = async (query: IListRequestQuery) => {
    const res = await Request.call('/api/products', 'GET', undefined, query, undefined, );
    return res;
  }
  listDemo = async () => {
    const res = await Request.call('/api/products/demo', 'GET', undefined, undefined, undefined, );
    return res;
  }
  upsert = async (body: IUpsertRequestBody) => {
    const res = await Request.call('/api/products', 'POST', undefined, undefined, body, );
    return res;
  }
  remove = async (params: IRemoveRequestParams) => {
    const res = await Request.call('/api/products/:id', 'DELETE', params, undefined, undefined, );
    return res;
  }
  detail = async (params: IDetailRequestParams) => {
    const res = await Request.call('/api/products/:id', 'GET', params, undefined, undefined, );
    return res;
  }
  listBySKUs = async (body: IListBySKUsRequestBody) => {
    const res = await Request.call('/api/products/list-by-skus', 'POST', undefined, undefined, body, );
    return res;
  }
  orderASample = async (body: IOrderASampleRequestBody) => {
    const res = await Request.call('/api/product-library/order-a-sample', 'POST', undefined, undefined, body, );
    return res;
  }
}
export default new ProductAPI()