import {
  Col,
  Row,
  Text,
  CMSLayout,
  useUIState,
  TouchField,
  Button,
  Table,
} from "components";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { IScreen } from "type";
import Store from "store";
import { useNavFunc } from "navigation";
import { SCREEN, COLOR } from "const";
import { Ionicons } from "@expo/vector-icons";
import { TimeHelper, ValHelper } from "helpers";
import * as Animatable from 'react-native-animatable';
import { useDynamicResponsiveValue } from "quickly-react";
import { IResellerItem } from "store/User.Store";
import { Pagination } from "antd";
import { ColumnsType } from "antd/es/table";
import { debounce } from 'lodash';
import apiClient from "store/api-client";

const PAGE_SIZE = 20;
const ListResellers: IScreen = () => {
  const { navigate, navigation, route } = useNavFunc();
  // @ts-ignore
  const { query } = route.params || {};
  const rV = useDynamicResponsiveValue();
  const isMobile = rV({ xs: true, lg: false });
  const UserStore = Store.useUserStore();
  const [{ fetching, errorMes, loading: btnLoading }, setUI] = useUIState({
    fetching: false,
  });
  const [users, setUsers] = useState<IResellerItem[]>([]);
  const [fetchingBalances, setFetchingBalances] = useState({});

  const [page, setPage] = useState(1);
  const [total, setTotal] = useState(0);
  const sorter = useRef<{ [key: string]: any }>();
  const filter = useRef<{ [key: string]: any }>();

  const getData = useCallback(async (p = 1) => {
    setUI({ fetching: true, errorMes: "" });

    const params: any = {
      page: p,
      pageSize: PAGE_SIZE,
      search: query ? encodeURIComponent(query) : '',
      countDesign: true,
      countStore: true,
    }
    if (sorter.current?.order) {
      params.sortBy = sorter.current.columnKey
      params.sortOrder = sorter.current.order === "descend" ? "DESC" : "ASC"
    }
    if (filter.current?.hasStore) {
      let hasStoreStr = filter.current?.hasStore?.join(",");
      if (hasStoreStr === "Yes") {
        params.hasStore = true;
      } else if (hasStoreStr === "No") {
        params.hasStore = false;
      }
    }
    try {
      const res = await apiClient.Api.User.listResellers(params);
      setUsers(res?.data?.data?.list || []);
      setTotal(res?.data?.data?.total || 0);
    } catch (error) {
      setUI({ fetching: false, errorMes: error?.message || 'Something went wrong' });
    } finally {
      setUI({ fetching: false, errorMes: "" });
    }
  }, [query]);

  const debouncedGetData = useCallback(
    debounce(getData, 300),
    [query]
  );

  useEffect(() => {
    debouncedGetData(page);
  }, [page, query]);

  const onChangeFilter = useCallback((_, _filter, _sorter) => {
    sorter.current = _sorter;
    filter.current = _filter;
    setPage(1);
    debouncedGetData(1);
  }, []);

  const renderJobListMobile = () => {
    if (users.length === 0) {
      return (
        <Col flex1>
          <Text>No reseller found</Text>
        </Col>
      );
    }
    return (
      <Col flex1>
        {users.map((val, i) => {
          const colStyle = {
            width: 'calc(50% - 10px)',
            m0: true, p0: true,
          }
          // @ts-ignore
          const balanceText = val.balanceText || '';
          return (
            <Row m0 p0 round0 shadow flexWrap={'wrap'} alignItems={'flex-start'}>
              <Col {...colStyle} width={'100%'}>
                <Text color={COLOR.GREY} fontSize={10} mb0>Name</Text>
                <Text h3 mb1>{val.firstName} {val.lastName}</Text>
              </Col>
              <Col {...colStyle}>
                <Text color={COLOR.GREY} fontSize={10} mb0>Account Name</Text>
                <Text>{val.accountName || '...'}</Text>
              </Col>
              <Col {...colStyle}>
                <Text color={COLOR.GREY} fontSize={10} mb0>Wallet Balance</Text>
                <Row>
                  <Text mr1>{balanceText}</Text>
                  <TouchField width={24} height={24} borderRadius={12} middle mr2
                    onPress={async () => {
                      setFetchingBalances({ ...fetchingBalances, [val.id]: true });
                      const { amount, formatted } = await UserStore.getResellerWalletBalance(val.id);
                      setFetchingBalances({ ...fetchingBalances, [val.id]: false });
                      const newList = users.slice();
                      // @ts-ignore
                      newList[i].balanceText = formatted;
                      newList[i].walletBalance = amount;
                    }}
                  >
                    <Animatable.View
                      animation={fetchingBalances[val.id] ? 'rotate' : null}
                      duration={1000}
                      iterationCount="infinite"
                    >
                      <Ionicons name="reload" size={14} color="black" />
                    </Animatable.View>
                  </TouchField>
                </Row>
              </Col>
              <Col {...colStyle}>
                <Text color={COLOR.GREY} fontSize={10} mb0>Number of designs</Text>
                <Text>{val.designCount || '0'}</Text>
              </Col>
              <Col {...colStyle}>
                <Text color={COLOR.GREY} fontSize={10} mb0>Store connected</Text>
                <Text>{val.hasStore ? 'Yes' : 'No'}</Text>
              </Col>
              <Col {...colStyle}>
                <Text color={COLOR.GREY} fontSize={10} mb0>Created At</Text>
                <Text>{TimeHelper.formatDay(val.createdAt)}</Text>
              </Col>
              <Row {...colStyle} flexWrap={'wrap'} marginHorizontal={-5}>
                <Col
                  p0 m0 round0 backgroundColor={COLOR.GREY_BG}
                  onPress={() =>
                    navigate(SCREEN.UpsertReseller, { id: val.id, tab: 'info' })
                  }
                >
                  <Text caption>Info</Text>
                </Col>
                <Col
                  p0 m0 round0 backgroundColor={COLOR.GREY_BG}
                  onPress={() =>
                    navigate(SCREEN.UpsertReseller, { id: val.id, tab: 'transactions' })
                  }
                >
                  <Text caption>Transactions</Text>
                </Col>
              </Row>
            </Row >
          )
        })}
      </Col >
    );
  }

  const columns: ColumnsType<IResellerItem> = [
    {
      title: 'Name',
      key: 'name',
      render: (text, record) => {
        return (
          <Col>
            <Text mb0>
              {record.firstName} {record.lastName}
            </Text>
            <Text caption>{record.email}</Text>
          </Col>
        )
      }
    },
    {
      title: 'Account Name',
      key: 'accountName',
      dataIndex: 'accountName',
      sorter: true
    },
    {
      title: 'Wallet Balance',
      key: 'walletBalance',
      render: (text, record) => {
        return (
          <Row>
            <TouchField cirle middle mr1
              onPress={async () => {
                setFetchingBalances({ ...fetchingBalances, [record.id]: true });
                const { amount } = await UserStore.getResellerWalletBalance(record.id);
                setFetchingBalances({ ...fetchingBalances, [record.id]: false });
                setUsers(s => {
                  const newList = [...s];
                  const targetIndex = newList.findIndex(val => val.id === record.id);
                  newList[targetIndex].walletBalance = amount;
                  return newList;
                });
              }}
            >
              <Animatable.View
                animation={fetchingBalances[record.id] ? 'rotate' : null}
                duration={1000}
                iterationCount="infinite"
              >
                <Ionicons name="reload" size={14} color="black" />
              </Animatable.View>
            </TouchField>
            <Text>£ {ValHelper.formatMoney(record.walletBalance)}</Text>
          </Row>
        )
      },
      sorter: true
    },
    {
      title: 'Created At',
      key: 'createdAt',
      render: (text, record) => {
        return <Text>{TimeHelper.formatDay(record.createdAt)}</Text>
      },
      sorter: true
    },
    {
      title: 'Number of designs',
      key: 'designCount',
      render: (text, record) => {
        return <Text textAlign="center">{String(record.designCount || 0).trim()}</Text>
      },
      sorter: true
    },
    {
      title: 'Store connected',
      key: 'hasStore',
      render: (text, record) => {
        return <Text textAlign="center">{record.hasStore ? 'Yes' : 'No'}</Text>
      },
      filters: ["Yes", "No"].map(i => ({ text: i, value: i })),
    },
    {
      title: '',
      key: 'actions',
      render: (text, record) => {
        return (
          <Row flex1 m1 justifyContent={"center"} flexWrap={'wrap'}>
            <TouchField p0 m0
              onPress={() =>
                navigate(SCREEN.UpsertReseller, { id: record.id, tab: 'info' })
              }
            >
              <Text caption>Info</Text>
            </TouchField>
            <Text> / </Text>
            <TouchField p0 m0
              onPress={() =>
                navigate(SCREEN.UpsertReseller, { id: record.id, tab: 'transactions' })
              }
            >
              <Text caption>Transactions</Text>
            </TouchField>
          </Row>
        )
      }
    }
  ].filter(Boolean);

  const renderJobList = () => {
    return (
      <>
        <Table
          data={users}
          columns={columns}
          rowKey={val => `${val.id}`}
          onChange={onChangeFilter}
          loading={fetching && page === 1}
          minWidth={800}
        />
        <Pagination
          style={{ marginTop: 12 }}
          pageSize={PAGE_SIZE}
          current={page}
          total={total}
          onChange={setPage}
          showSizeChanger={false}
        />
      </>
    )
  };

  return (
    <CMSLayout
      requireAuthen
      searchPlaceholder='Search by Name or Email'
      onSearchInput={(keyword) => {
        navigation.setParams({ query: keyword.trim() });
      }}
      onClearSearchInput={() => {
        navigation.setParams({ query: '' });
      }}
      searchInitialValue={query}
    >
      <Row m2 marginBottom={0} justifyContent={"space-between"}>
        <Text h3>Resellers</Text>
        <Button
          isLoading={btnLoading}
          text="Add a new reseller"
          width={200}
          height={40}
          borderRadius={20}
          onPress={async () => {
            // navigate(SCREEN.UpsertReseller, { id: 'new' });
            navigation.reset({
              index: 0,
              routes: [{ name: SCREEN.UpsertReseller, params: { id: "new" } }],
            });
          }}
        />
      </Row>
      <Col flex1 m2 mv1 p2 round1 bgWhite>
        {errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>
              {errorMes}
            </Text>
          </Col>
        ) : (
          isMobile ? renderJobListMobile() : renderJobList()
        )}
      </Col>
    </CMSLayout>
  );
};

ListResellers.routeInfo = {
  title: "Resellers - Bottled Goose",
  path: "/resellers",
};

export default ListResellers;
