import BottleGoose from './svg/BottleGoose';
import Eye from './svg/Eye';
import Note from './svg/Note';
import Pencil from './svg/Pencil';
import Connect from './svg/Connect';
import GirlBanner1 from './svg/GirlBanner1';
import EyeOpen from './svg/EyeOpen';
import EyeClose from './svg/EyeClose';
import CheckIcon from './svg/CheckIcon';
import WorkInProgress from './svg/WorkInProgress';
import GoogleIcon from './svg/GoogleIcon';
import FacebookIcon from './svg/FacebookIcon';

export const SVG = {
  BottleGoose,
  Eye,
  Note,
  Pencil,
  Connect,
  GirlBanner1,
  EyeOpen,
  EyeClose,
  CheckIcon,
  WorkInProgress,
  GoogleIcon,
  FacebookIcon,
}

export const ASSETS = {
  SHOPIFY: require('./img/shopify.png'),
  LOGO_MOBILE: require('./img/Logo.png'),
  MESS_MOBILE: require('./img/welcome-image.svg'),
  WOOCOMMERCE: require('./img/woocommerce.png'),
  LOGO: require('./img/whitebottledgoosenew@4x.png'),
  CUSTOMER_ADDRESS_ICON: require('./img/address.png'),
  SHOPIFY_TEXT: require('./img/shopify-text-logo.png'),
  GRAB_ADMIN_URL: require('./img/grab_admin_url.png'),
  ADD_PERSONALISED_BUTTON: require('./img/add_personalised_button.png'),
  CUSTOMIZE_ADD_BLOCKS_1: require('./img/customize_add_blocks_1.png'),
  CUSTOMIZE_ADD_BLOCKS_2: require('./img/customize_add_blocks_2.png'),
  ETSY_LOGO: require('./img/etsy.svg'),
  ARROW: require('./img/arrow.svg'),
  PHONE: require('./img/phone.svg'),
  MAIL: require('./img/mail.svg'),
  DOT: require('./img/dot.svg'),
  GET_STARTED_STEP_1_1: require('./img/get-started-1-1.svg'),
  GET_STARTED_STEP_1_2: require('./img/get-started-1-2.svg'),
  GET_STARTED_STEP_1_3: require('./img/get-started-1-3.svg'),
  GET_STARTED_STEP_1_4: require('./img/get-started-1-4.svg'),
  GET_STARTED_STEP_3: require('./img/get-started-3.svg'),
  GET_STARTED_STEP_4: require('./img/get-started-4.svg'),
  STEP_2_ARROW: require('./img/step2-arrow.svg'),
  ARROW_UP: require('./img/arrow-up.svg'),
  ARROW_DOWN: require('./img/arrow-down.svg'),
  QUESTIONMARK: require('./img/question-mark.svg'),
  BG_LOGO_SMALL: require('./img/BG_small.webp'),
  BG_SMALL_BLUE: require('./img/BG_small_Blue.png'),
}
