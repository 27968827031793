import React, { useEffect, useState } from 'react';
import { Col, InputField, SelectField, TableForm, Text, Row } from 'components';
import { Switch } from "antd";

interface IProps {
  data: {
    name: string;
    title: string;
    description: string;
    type: string;
    choices?: {
      value: string;
      text: string;
      imageLink?: string;
    }[];
    multiSelect?: boolean;
    isRequired?: boolean;
    showLabel?: boolean;
    showOtherItem?: boolean;
    // choicesByUrl?: {
    //   url: string;
    // };
  },
  onChange?: (data: any) => void
}

const ELEMENT_TYPES = [
  { label: 'Text Input', value: 'text' },
  { label: 'Checkbox', value: 'checkbox' },
  { label: 'Multi-Select Dropdown', value: 'tagbox' },
  { label: 'Radio Group', value: 'radiogroup' },
  { label: 'Dropdown', value: 'dropdown' },
  { label: 'Image Picker', value: 'imagepicker' },
]

const ElementForm = ({ data, onChange }: IProps) => {
  const [options, setOptions] = useState(data?.choices || [])

  const onChangeOptions = (newOptions) => {
    const formattedOptions = newOptions.map(option => ({
      ...option,
      text: option.value
    }));
    setOptions(formattedOptions)
    onChange?.({
      ...data,
      choices: formattedOptions
    })
  }

  const onChangeData = (key: string) => (val: any) => {
    onChange?.({
      ...data,
      [key]: val
    })
  }

  const showChoices = ['radiogroup', 'checkbox', 'dropdown', 'imagepicker', 'tagbox'].includes(data.type);
  const showMultiSelect = data.type === 'imagepicker'; // ['radiogroup', 'dropdown'].includes(data.type);
  const showImageOptions = data.type === 'imagepicker';
  const showLabelOption = data.type === 'imagepicker';
  const showOtherOption = ['radiogroup', 'checkbox', 'dropdown', 'tagbox'].includes(data.type);
  // const showUrlInput = data.type === 'tagbox';

  return (
    <Col flex1 bgWhite>
      <SelectField
        title="Type"
        options={ELEMENT_TYPES}
        value={data.type}
        onChange={onChangeData('type')}
        isMultiple={false}
        mt1
      />
      <InputField
        title="Name"
        value={data.name}
        onChangeText={onChangeData('name')}
        mt1
      />
      <InputField
        title="Title"
        value={data.title}
        onChangeText={onChangeData('title')}
        mt1
      />
      <InputField
        title="Description"
        value={data.description}
        onChangeText={onChangeData('description')}
        mt1
      />
      <Col mt1 alignItems="flex-start">
        <Text mb1>Required</Text>
        <Switch
          checked={data?.isRequired}
          onChange={onChangeData('isRequired')}
        />
      </Col>
      {showMultiSelect && (
        <Col mt1 alignItems="flex-start">
          <Text mb1>Multi Select</Text>
          <Switch
            checked={data.multiSelect}
            onChange={onChangeData('multiSelect')}
          />
        </Col>
      )}
      {showLabelOption && (
        <Col mt1 alignItems="flex-start">
          <Text mb1>Show Label</Text>
          <Switch
            checked={data.showLabel}
            onChange={onChangeData('showLabel')}
          />
        </Col>
      )}
      {showOtherOption && (
        <Col mt1 alignItems="flex-start">
          <Text mb1>Show Other Option</Text>
          <Switch
            checked={data.showOtherItem}
            onChange={onChangeData('showOtherItem')}
          />
        </Col>
      )}
      {/* <Col mt1 alignItems="flex-start">
        <Text mb1>Show None Option</Text>
        <Switch
          checked={data.showNoneItem}
          onChange={onChangeData('showNoneItem')}
        />
      </Col> */}
      {/* <Col mt1 alignItems="flex-start">
        <Text mb1>Show Select All Option</Text>
        <Switch
          checked={data.showSelectAllItem}
          onChange={onChangeData('showSelectAllItem')}
        />
      </Col>
      <Col mt1 alignItems="flex-start">
        <Text mb1>Separate Special Choices</Text>
        <Switch
          checked={data.separateSpecialChoices}
          onChange={onChangeData('separateSpecialChoices')}
        />
      </Col> */}
      {/* {showUrlInput && (
        <InputField
          title="Choices URL"
          value={data.choicesByUrl?.url}
          onChangeText={(val) => onChange?.({
            ...data,
            choicesByUrl: { url: val }
          })}
          mt1
        />
      )} */}
      {showChoices && (
        <TableForm
          addBtnTitle="Add option"
          data={options}
          columns={[
            { key: 'value', label: 'Value' },
            // { key: 'text', label: 'Text' },
            ...(showImageOptions ? [{ key: 'imageLink', label: 'Image URL', canUpload: true }] : [])
          ]}
          editable
          onChange={onChangeOptions}
          isBottomBtn
          marginBottom={24}
        />
      )}
    </Col>
  )
};

export default ElementForm;
