
import { Col, Text, Row, Grid, } from 'components/base';
import { BgIcon, Button, ImageGallery } from 'components/elements';
import { ValHelper } from 'helpers';
import { useDynamicResponsiveValue } from 'quickly-react';
import React from 'react';
import { Pressable, StyleSheet } from 'react-native';

interface IProps {
  product?: any;
  onCtaBtnClick?: () => void;
  onBtnNormalClick?: () => void;
  ctaBtnLoading?: boolean;
}

const ProductDetail = ({ onCtaBtnClick, onBtnNormalClick, product, ctaBtnLoading }: IProps) => {
  const rV = useDynamicResponsiveValue(["xs", "md", "lg"]);
  const padding = rV({ xs: 8, md: 12, lg: 20 });

  if (!product) return null

  const isDiscounted = product.originalPrice !== product.price;
  return (
    <Col marginHorizontal={padding}>
      <Grid xs='100%' md='100%' lg="50%" alignItems="flex-start">
        <Col padding={padding}>
          <ImageGallery
            mainImage={product.image}
            images={product.galleries || []}
          />
        </Col>
        <Col padding={padding}>
          <Col padding={padding} borderRadius={10} backgroundColor={"#edeffe"} flex1>
            <Text fontSize={26} fontWeight={"bold"}>{product.name}</Text>
            <Row justifyContent={"flex-start"} alignItems={"center"} marginTop={20}>
              <Button
                height={40}
                borderRadius={15}
                width={180}
                text='Get Started'
                textStyle={{ fontWeight: "600", fontSize: 18 }}
                onPress={onCtaBtnClick}
                disabled={ctaBtnLoading}
                isLoading={ctaBtnLoading}
              />
              <Pressable onPress={onBtnNormalClick} style={styles.noOutLine}>
                <Row>
                  <Text fontSize={16} marginLeft={20} color={"#3b52bf"} marginRight={10}>Download Artboard</Text>
                  <BgIcon name="download-svgrepo-com" size={16} color="black" />
                </Row>
              </Pressable>
            </Row>
            <Row height={4} backgroundColor={'#D6E0FF'} marginTop={20}></Row>
            <Col padding={padding}>
              <Row alignItems={"flex-start"}>
                <Col flex1>
                  <Text style={styles.subTitle}>Price</Text>
                  <Text fontSize={17} fontWeight={"bold"} marginTop={20}>
                    {isDiscounted && (
                      <Text style={{ textDecorationLine: 'line-through', marginRight: 6 }}>
                        £{ValHelper.formatMoney(product.originalPrice)}
                      </Text>
                    )}
                    £{ValHelper.formatMoney(product.price)} ex VAT
                  </Text>
                  <Text fontSize={16} color={"grey"} marginTop={5}>
                    (£{ValHelper.formatMoney(1.2 * product.price)} incl VAT)
                  </Text>
                </Col>
                <Col flex1>
                  <Text style={styles.subTitle} >Estimated lead time</Text>
                  <Text style={styles.content} >{product.estimatedLeadTime}</Text>
                </Col>
              </Row>
              <Row alignItems={"flex-start"}>
                <Col marginTop={10} flex1 >
                  <Text style={styles.subTitle}>Dimensions & Volume</Text>
                  <Text style={styles.content}>
                    Width: {product.productWidth}{product.unit}, Height: {product.productHeight}{product.unit}
                  </Text>
                  {!!product.volume && (
                    <Text style={styles.content}>Volume: {product.volume}</Text>
                  )}
                </Col>
                {!!product.material && (
                  <Col marginTop={10} alignItems={"flex-start"} flex1>
                    <Text style={styles.subTitle}>Material</Text>
                    <Col backgroundColor={"#b4dfb6"} borderRadius={5} marginTop={10} padding={5}>
                      <Text color={"#064b08"} fontSize={16} fontWeight={"bold"}>{product.material}</Text>
                    </Col>
                  </Col>
                )}
              </Row>
              <Text style={styles.subTitle}>Description</Text>
              <Text style={styles.content}>{product.description}</Text>
              <Text style={styles.subTitle}>How is the product packaged?</Text>
              <Text style={styles.content}>{product.packagingDescription}</Text>
            </Col>
          </Col>
        </Col>
      </Grid>
    </Col>
  )
}
const styles = StyleSheet.create({
  subTitle: {
    fontSize: 21,
    color: "#1c2127",
    fontWeight: "bold",
    marginTop: 20,
    flex: 1
  },
  content: {
    fontSize: 16,
    color: "#1c2127",
    marginTop: 15,
    fontWeight: "500"
  },
  noOutLine: {
    // @ts-ignore
    outline: "none"
  }
})
export default ProductDetail;
