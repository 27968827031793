
import { Col, Row } from "components";
import React, { useMemo, useState } from "react";
import ImageItem from "./ImageItem";
import { ScrollView, useWindowDimensions } from "react-native";
import { COLORS } from "const";


const ProductImages = ({ images, mainImage, onPressRemove }: {
  images: string[], mainImage?: string, onPressRemove?: (idx: number) => void,
}) => {
  const [selectedImageIdx, setSelectedImageIdx] = useState<number>(0);
  const smallImages = useMemo(() => (
    [mainImage, ...images]
  ), [mainImage, images])

  const { height } = useWindowDimensions();

  const mainImgMaxHeight = height - 280;

  return (
    <Col>
      <ImageItem
        uri={smallImages[selectedImageIdx]}
        style={{ width: "100%", maxWidth: mainImgMaxHeight, borderRadius: 8, alignSelf: "center" }}
        canDownload
        canPreview={false}
      />
      <ScrollView
        style={{ width: "100%" }}
        contentContainerStyle={{ padding: 4, justifyContent: "center", flexGrow: 1 }}
        horizontal
        showsHorizontalScrollIndicator={false}
      >
        {smallImages?.map((i, idx) => (
          <ImageItem
            uri={i}
            key={`${i}${idx}`}
            marginRight={16}
            marginTop={16}
            canRemove={typeof onPressRemove === "function"}
            handleOnPress={() => {
              setSelectedImageIdx(idx)
            }}
            canPreview={false}
            style={{
              borderRadius: 3,
              borderWidth: selectedImageIdx === idx ? 2 : 0,
              borderColor: selectedImageIdx === idx ? COLORS.BLUE : "transparent"
            }}
          />
        ))}
      </ScrollView>
    </Col>
  )
}

export default ProductImages;
