import Request from '../Request.utils'
export interface IUpdateSurveyFormRequestBody {
  data?: string;
  type: string;
}
export interface IGetSurveyFormRequestParams {
  type: string;
}
export interface ISubmitAnwserRequestBody {
  data?: object;
  type: string;
}
export interface IGetSurveyAnwserRequestQuery {
  keyword?: string;
  limit: number;
  offset: number;
  sortField?: string;
  sortOrder?: string;
  type: string;
}


class SurveyAPI {
  updateSurveyForm = async (body: IUpdateSurveyFormRequestBody) => {
    const res = await Request.call('/api/survey/update-form', 'POST', undefined, undefined, body, );
    return res;
  }
  getSurveyForm = async (params: IGetSurveyFormRequestParams) => {
    const res = await Request.call('/api/survey/get-form/:type', 'GET', params, undefined, undefined, );
    return res;
  }
  submitAnwser = async (body: ISubmitAnwserRequestBody) => {
    const res = await Request.call('/api/survey/submit-answer', 'POST', undefined, undefined, body, );
    return res;
  }
  getSurveyAnwser = async (query: IGetSurveyAnwserRequestQuery) => {
    const res = await Request.call('/api/survey/get-anwser', 'GET', undefined, query, undefined, );
    return res;
  }
}
export default new SurveyAPI()