import React, { useEffect, useState, useRef, useMemo } from "react";
import { IScreen } from "type";
import {
  CMSLayout,
  Col,
  Row,
  Text,
  Button,
  ShimmerLoading,
  TouchField,
  Input02,
  Select01,
  RatioCol,
  RatioCol2,
  UploadFile,
  useUIState,
  useRefState,
  Grid,
  modal,
  TextArea,
  showPopupMessage,
  BgIcon,
} from "components";
import { useNavFunc } from "navigation";
import { useDynamicResponsiveValue } from "quickly-react";
import { COLOR, SCREEN } from "const";
import Store from "store";
import { Image } from "react-native";
import {
  Entypo,
  AntDesign,
  EvilIcons,
  FontAwesome5,
  MaterialCommunityIcons,
  MaterialIcons,
} from "@expo/vector-icons";
import PrintAreas from "./UpsertProduct.PrintAreas";
import { saveAs } from "file-saver";
import { INCH_TO_MM } from "const";
import ActivateModal from "./UpsertProduct.Activate.Modal";
import UploadBlendFile, {
  readBlendDataFromProduct,
} from "./UpsertProduct.UploadBlendFile";
import { Switch } from "antd";
import { useNavigationMiddleWare } from "navigation/NavigationContext";
import ProductImages from "./UpsertProduct.ProductImages";
import { ValHelper } from "helpers";
import UpsertProductPricing from "./UpsertProduct.Pricing";

const TAB = {
  LISTING_INFO: "LISTING_INFO",
  PRINT_INFO: "PRINT_INFO",
  PRICING: "PRICING",
  items: [
    { title: "Listing Info", key: "LISTING_INFO" },
    { title: "Print Info", key: "PRINT_INFO" },
    { title: "Pricing", key: "PRICING" },
  ].filter(Boolean),
};

type TSelectResellerOption = {
  label: string;
  value: string;
  data?: any;
};

const LeftColumn = Col;
const MiddleColumn = Col;
const RightColumn = Col;

type TVariantOption = {
  variant: string;
  prices: Array<{
    amount: any;
    price: any;
  }>;
};

const screenName = SCREEN.UpsertProduct;

const UpsertProduct: IScreen = () => {
  const UserStore = Store.useUserStore();
  const ProductStore = Store.useProductStore();
  const { navigation, route } = useNavFunc();
  const { updateEditting } = useNavigationMiddleWare();
  // @ts-ignore
  const { id } = route.params || {};
  const { product, setProduct, uiState, hasDoneSomeEditing } =
    ProductStore.useProduct(id);
  const productRef = useRef(product);
  useEffect(() => {
    productRef.current = product;
  }, [product]);

  const physicalRatio =
    !product || !product.physicalHeight || !product.physicalWidth
      ? 1
      : product.physicalWidth / product.physicalHeight;

  const canEdit = UserStore.user?.role === "admin";

  const [curTab, setCurTab] = useState(TAB.LISTING_INFO);

  const [listResellers, setListResellers] = useState<
    Array<TSelectResellerOption>
  >([]);
  const [selectedResellers, setSelectedResellers] = useState<
    Array<TSelectResellerOption>
  >([]);

  const [unit, setUnit] = useState<"inch" | "mm">("mm");
  const [show360ProductEditor, setShow360ProductEditor] =
    useState<boolean>(false);
  const [{ loading: upload3dLoading }, setUpload3dUI] = useUIState();

  const blendData = useMemo(() => {
    return readBlendDataFromProduct(product);
  }, [product]);

  const updateProduct = (obj) => {
    setProduct((d: any) => {
      return {
        ...d,
        ...obj,
      };
    });
    updateEditting(screenName, true);
  };

  const updateProductData = (obj) => {
    setProduct((d: any) => {
      return {
        ...d,
        data: {
          ...d?.data,
          ...obj,
        },
      };
    });
    updateEditting(screenName, true);
  };

  const onEditorMessage = async (
    data: { event: string; payload: any } | undefined
  ) => {
    console.log("onEditorMessage", data);
    if (!!data && data.event === "PRODUCT_LIBRARY_SAVE") {
      if (!!data.payload && data.payload.productId === id) {
        const res = await Store.Api.Product.detail({ id });
        if (res.data.success)
          setProduct({
            ...productRef.current,
            data: {
              ...productRef.current.data,
              preview: res.data.data.data?.preview,
            },
          });
      }
    }
  };

  const variantOptions: Array<TVariantOption> =
    product?.variations && product?.variations.length > 0
      ? product?.variations
      : [
        {
          variant: "No Var + No Mirr",
          prices: [
            { amount: 1, price: "" },
            { amount: 10, price: "" },
            { amount: 25, price: "" },
            { amount: 50, price: "" },
            { amount: 100, price: "" },
            { amount: 250, price: "" },
            { amount: 350, price: "" },
          ],
        },
        {
          variant: "Var + No Mirr",
          prices: [
            { amount: 1, price: "" },
            { amount: 10, price: "" },
            { amount: 25, price: "" },
            { amount: 50, price: "" },
            { amount: 100, price: "" },
            { amount: 250, price: "" },
            { amount: 350, price: "" },
          ],
        },
        {
          variant: "Var + Mirr",
          prices: [
            { amount: 1, price: "" },
            { amount: 10, price: "" },
            { amount: 25, price: "" },
            { amount: 50, price: "" },
            { amount: 100, price: "" },
            { amount: 250, price: "" },
            { amount: 350, price: "" },
          ],
        },
      ];

  const candleTemplates = [
    {
      value:
        "https://print-manager-media.s3.amazonaws.com/candle-template/black-candle-template.png",
      label: "Black Vessel",
    },
    {
      value:
        "https://print-manager-media.s3.amazonaws.com/candle-template/clear-candle-template.png",
      label: "Clear Vessel",
    },
    {
      value:
        "https://print-manager-media.s3.amazonaws.com/candle-template/white-candle-template.png",
      label: "White Vessel",
    },
  ];

  const setVariantOptions = (newOptions: Array<TVariantOption>) => {
    // setVariantOptions(product.variations);
    setProduct({
      ...product,
      variations: newOptions,
    });
  };

  // useEffect(() => {
  //   if (!product) return;
  //   if (product.variations && product.variations.length > 0) {
  //     setVariantOptions(product.variations);
  //   }
  // }, [product]);

  const [{ loading: imageLoading }, setImageUI] = useUIState();
  const [{ loading: submitting }, setSubmitUI] = useUIState();
  const [{ loading: removing }, setRemoveUI] = useUIState();

  const [imageId, getImageId, setImageId] = useRefState<
    | "main"
    | "galleries"
    | "blueprint"
    | "3d-model"
    | "packaging"
    | "artboard"
    | "droplet"
    | "banner"
  >("main");

  const [focusedArea, setFocusedArea] = useState(-1);

  useEffect(() => {
    (async () => {
      const { list } = await UserStore.getListResellers(1);
      if (list && list.length > 0) {
        setListResellers([
          { label: "All users", value: "all" },
          ...list.map((v) => ({
            label: `${v.firstName} ${v.lastName}`,
            value: v.id,
          })),
        ]);
      }
    })();
  }, []);

  useEffect(() => {
    setShow360ProductEditor(false);
  }, [id]);

  const uploadRef = useRef<{ showDialog: Function }>(null);

  const recommendedDPI = useMemo(() => {
    const w = Number(product?.physicalWidth) || 0;
    const h = Number(product?.physicalHeight) || 0;
    if (w === 0 && h === 0) return 0;
    return ValHelper.calculateDPI(w, h);
  }, [product?.physicalWidth, product?.physicalHeight]);

  useEffect(() => {
    if (!recommendedDPI) return;
    if (!!product && !product?.dpi) {
      updateProduct({ dpi: recommendedDPI });
    }
  }, [product, recommendedDPI]);

  useEffect(() => {
    if (product?.availableForResellerIds) {
      const resellerIds = Object.keys(product?.availableForResellerIds).filter(
        (id) => !!product?.availableForResellerIds[id]
      );
      const selected = [];
      resellerIds.forEach((id) => {
        const findReseller = listResellers.find((v) => v.value === id);
        if (findReseller) selected.push(findReseller);
      });
      setSelectedResellers(selected);
    }
  }, [product?.availableForResellerIds, listResellers]);

  const onImageUploaded = (urls) => {
    console.log(getImageId());
    console.log("onImageUploaded urls", urls);
    setUpload3dUI({ loading: false });
    if (urls.length === 0) return setImageUI({ loading: false });
    let newProduct = { ...product };
    switch (getImageId()) {
      case "packaging":
        newProduct.packagingImage = urls[0];
        break;
      case "artboard":
        newProduct.artboardUrl = urls[0];
        break;
      case "main":
        newProduct.image = urls[0];
        break;
      case "banner":
        newProduct.banner = urls[0];
        break;
      case "galleries":
        newProduct.previewData = [
          ...(newProduct.previewData || []),
          ...urls.map((v) => ({
            image: v,
            title: "",
            data: {},
          })),
        ];
        break;
      case "blueprint":
        newProduct.bluePrintImage = urls[0];
        break;
      case "3d-model":
        const previewData = (newProduct?.previewData || []).slice();
        const find3DPreviewIndex = previewData.findIndex(
          (v) => v.groupTitle === "3D preview"
        );
        if (find3DPreviewIndex === -1) {
          previewData.push({
            groupTitle: "3D preview",
            previewType: "3d-model",
            previewItems: [
              {
                title: "360 View",
                // data: `/gltf-viewer/index.html?model=${urls[0]}`,
                data: `/3dd/index.html?load=${urls[0]}`,
                modelUrl: urls[0],
                image: "",
              },
            ],
          });
        } else {
          previewData[find3DPreviewIndex].previewItems[0] = {
            title: "360 View",
            // data: `/gltf-viewer/index.html?model=${urls[0]}`,
            data: `/3dd/index.html?load=${urls[0]}`,
            modelUrl: urls[0],
            image: "",
          };
        }
        newProduct.previewData = previewData;
        break;
      case "droplet":
        newProduct.dropletUrl = urls[0];
        break;
    }
    setProduct(newProduct);
    updateEditting(screenName, true);
    setImageUI({ loading: false });
  };

  const validateNumberInput = (label) => () => {
    if (!product) return;
    const value = (() => {
      if (!label.includes(".")) return product[label];
    })();
    if (!value) return;
    if (isNaN(+value)) {
      showPopupMessage({
        title: "",
        content: "Please input number value.",
        buttonOkText: "OK",

        typeHighlight: "danger",
        contentHighlight: "Error",
      });
      // alert('Please input number value');
      updateProduct({ [label]: 0 });
    } else {
      updateProduct({ [label]: +value });
    }
  };
  const onChangePrintArea = (label, index) => (newValue) => {
    const areas = (product?.printAreas || []).slice();
    areas[index][label] = newValue;
    updateProduct({
      printAreas: areas,
    });
  };
  const onBlurPrintArea = (label, index) => () => {
    setFocusedArea(-1);
    const areas = (product?.printAreas || []).slice();
    const value = areas[index][label];
    if (isNaN(+value)) {
      showPopupMessage({
        title: "",
        content: "Please input number value.",
        buttonOkText: "OK",

        typeHighlight: "danger",
        contentHighlight: "Error",
      });
      // alert('Please input number value');
      areas[index][label] = 0;
      console.log("areas", areas);
      updateProduct({ printAreas: areas });
    } else {
      areas[index][label] = +value;
      updateProduct({ printAreas: areas });
    }
  };

  const onChangePDFSKU = (label, index) => (newValue) => {
    console.log("newValue", newValue);
    const skuPDFs = (product?.data?.skuPDFs || []).slice();
    skuPDFs[index][label] = newValue;
    updateProductData({
      skuPDFs,
    });
  };

  const onToggleStock = (val) => {
    updateProductData({
      isOutStock: val,
    });
  };

  const renderTab = () => {
    return (
      <Row mb0 height={40} marginLeft={-5}>
        {TAB.items.map((val, i) => (
          <Button
            key={val.key}
            text={val.title}
            outline={val.key !== curTab}
            height={30}
            borderRadius={15}
            width={100}
            m0
            onPress={() => val.key !== curTab && setCurTab(val.key)}
            bgHovered={val.key !== curTab ? COLOR.GREY_BG : undefined}
          />
        ))}
      </Row>
    );
  };

  const renderTabPricing = () => {
    return (
      <Grid xs='100%' md='100%' lg='100%' xl='50%' alignItems='flex-start'>
        <Col p2>
          {renderTab()}
          <UpsertProductPricing
            product={product}
            updateProduct={updateProduct}
            updateProductData={updateProductData}
          />
        </Col>
        <Col>
        </Col>
      </Grid>
    );
  };

  const renderReady = () => {
    // if (breakpoint === "xs")
    //   return (
    //     <Col flex1 middle>
    //       <Text>Please use bigger screen to see this page.</Text>
    //     </Col>
    //   );

    if (curTab === TAB.LISTING_INFO) return renderTabListing()
    if (curTab === TAB.PRICING) return renderTabPricing()
    return renderTabPrint()
  };

  const renderTabListing = () => {
    const typeOptions = [
      { label: "No", value: "other" },
      { label: "Yes", value: "candle" },
    ];
    const labelOptions = [
      { label: "No label", value: "" },
      { label: "Best seller", value: "Best seller" },
      { label: "New In", value: "New In" },
      { label: "Exclusive", value: "Exclusive" },
      { label: "SALE NOW", value: "SALE NOW" },
    ];
    const secondaryLabelOptions = [
      { label: "No label", value: "" },
      { label: `Mother's Day designs available`, value: `Mother's Day designs available` },
    ];
    return (
      <Grid xs='100%' md='100%' lg='100%' xl='2:3' alignItems='flex-start'>
        <Col p2>
          <Row alignItems="center" justifyContent="space-between">
            {renderTab()}
            {UserStore.user?.role === "admin" && (
              <Row>
                <Text mr1>Out of stock</Text>
                <Switch
                  checked={product?.data?.isOutStock}
                  onChange={onToggleStock}
                />
              </Row>
            )}
          </Row>
          <Text caption mb1>
            * Listing Info is the information of the product that will be
            displayed in public {`(eg: Reseller website, Shopify website)`}
          </Text>

          <Text subtitle1 mb1>
            Product Name
          </Text>
          <Input02
            height={35}
            value={product?.name || ""}
            onChange={(v) => updateProduct({ name: v })}
            mb1
            inputProps={{ editable: canEdit }}
          />

          <Text subtitle1 mb0>
            Label
          </Text>
          <Text caption mb1>Label dispay at top corner of product thumbnail in listing</Text>
          <Select01
            placeholder="Select a label"
            value={labelOptions.find((v) => v.value === product?.label)}
            onChange={(newValues) => {
              updateProduct({
                label: newValues.value,
              });
            }}
            options={labelOptions}
            mb1
          />

          <Text subtitle1 mb0>
            Secondary Label
          </Text>
          <Text caption mb1>Secondary label display below the main label.</Text>
          {/* <Select01
            placeholder="Select a secondary label"
            value={secondaryLabelOptions.find((v) => v.value === product?.secondLabel)}
            onChange={(newValues) => {
              updateProduct({
                secondLabel: newValues.value,
              });
            }}
            options={secondaryLabelOptions}
            mb1
          /> */}
          <Input02
            height={35}
            value={product?.secondLabel || ""}
            onChange={(v) => updateProduct({ secondLabel: v })}
            mb1
            inputProps={{ editable: canEdit }}
          />
          <Text subtitle1 mb1>
            Original Price
          </Text>
          <Input02
            height={35}
            value={product?.originalPrice || ""}
            onChange={(v) => updateProduct({ originalPrice: v })}
            mb1
            inputProps={{ editable: canEdit }}
          />
          <Text subtitle1 mb1>
            Discounted Price
          </Text>
          <Input02
            height={35}
            value={product?.price || ""}
            onChange={(v) => updateProduct({ price: v })}
            mb1
            inputProps={{ editable: canEdit }}
          />
          <Text subtitle1 mb1>
            Estimated lead time
          </Text>
          <Input02
            height={35}
            value={product?.estimatedLeadTime || ""}
            onChange={(v) => updateProduct({ estimatedLeadTime: v })}
            mb1
            inputProps={{ editable: canEdit }}
          />
          <Text subtitle1 mb1>
            Volume
          </Text>
          <Input02
            height={35}
            value={product?.volume || ""}
            onChange={(v) => updateProduct({ volume: v })}
            mb1
            inputProps={{ editable: canEdit }}
          />
          <Text subtitle1 mb1>
            Material
          </Text>
          <Input02
            height={35}
            value={product?.material || ""}
            onChange={(v) => updateProduct({ material: v })}
            mb1
            inputProps={{ editable: canEdit }}
          />
          <Text subtitle1 mb1>
            Weight (g)
          </Text>
          <Input02
            height={35}
            value={product?.data?.weight || ""}
            onChange={(v) =>
              updateProductData({
                weight: v,
              })
            }
            mb1
            inputProps={{ editable: canEdit }}
          />
          <Text subtitle1 mb1>
            Size (mm)
          </Text>
          <Row>
            <Col flex1 mr1>
              <Text caption mb1>
                Width
              </Text>
              <Input02
                height={35}
                value={product?.productWidth || ""}
                onChange={(v) => updateProduct({ productWidth: v })}
                mb1
                onBlur={validateNumberInput("productWidth")}
                inputProps={{ editable: canEdit }}
              />
            </Col>
            <Col flex1>
              <Text caption mb1>
                Height
              </Text>
              <Input02
                height={35}
                value={product?.productHeight || ""}
                onChange={(v) => updateProduct({ productHeight: v })}
                mb1
                onBlur={validateNumberInput("productHeight")}
                inputProps={{ editable: canEdit }}
              />
            </Col>
          </Row>
          <Text subtitle1 mb1>
            Is this product a candle?
          </Text>
          <Select01
            placeholder="No"
            value={typeOptions.find((v) => v.value === product?.tags)}
            onChange={(newValues) => {
              updateProduct({
                tags: newValues.value,
              });
            }}
            options={typeOptions}
            mb1
          />

          {product?.tags === "candle" && (
            <>
              <Text subtitle1>Scent variations</Text>
              <Text caption mb1>
                * Separate by comma or line break
              </Text>
              <Col mb2>
                <TextArea
                  height={150}
                  value={product?.data?.scentVariants || ""}
                  onChangeText={(v) => {
                    updateProductData({
                      scentVariants: v,
                    });
                  }}
                  contentEditable={canEdit}
                />
              </Col>
              <Text subtitle1 mb1>
                Candle Label
              </Text>
              <Select01
                value={candleTemplates.find(
                  (v) => v.value === product?.data?.candleTemplate
                )}
                onChange={(v) => {
                  updateProductData({
                    candleTemplate: v.value,
                  });
                }}
                options={candleTemplates}
                mb1
              />
            </>
          )}

          {UserStore.user?.role === "admin" && (
            <>
              <Text subtitle1 mb1>
                Users:
              </Text>
              <Select01
                value={selectedResellers}
                isMulti
                onChange={(newVal) => {
                  setSelectedResellers(newVal);
                  // triggle save
                  setProduct({
                    ...product,
                  });
                  updateEditting(screenName, true);
                }}
                options={listResellers}
                mb1
              />
            </>
          )}

          <Text subtitle1 mb1>
            Description
          </Text>
          <Input02
            value={product?.description || ""}
            onChange={(v) => updateProduct({ description: v })}
            multiline
            mb1
            inputProps={{ multiline: true, editable: canEdit }}
            height={150}
          />

          <Row mb1 alignSelf="flex-start">
            <Text subtitle1>Packaging</Text>
            {/* <Button
              isLoading={imageLoading && imageId === "packaging"}
              outline
              ml1
              bgHovered={COLOR.GREY_BG}
              text="Upload packaging image"
              height={30}
              borderRadius={15}
              width={210}
              onPress={() => {
                setImageId("packaging");
                setImageUI({ loading: true });
                uploadRef.current.showDialog();
              }}
            /> */}
          </Row>
          {/* {!!product?.packagingImage && (
            <Text
              onPress={() => window.open(product.packagingImage)}
              mb1
              caption
              color={COLOR.MAIN}
            >
              {product?.packagingImage}
            </Text>
          )} */}
          <Col mb1>
            <TextArea
              value={product?.packagingDescription || ""}
              onChangeText={(v) => updateProduct({ packagingDescription: v })}
              contentEditable={canEdit}
              height={150}
            />
          </Col>
          <Row mb1 alignSelf="flex-start">
            <Text subtitle1>Artboard URL</Text>
            <Button
              isLoading={imageLoading && imageId === "artboard"}
              outline
              ml1
              bgHovered={COLOR.GREY_BG}
              text="Upload artboard"
              height={30}
              borderRadius={15}
              width={160}
              onPress={() => {
                setImageId("artboard");
                setImageUI({ loading: true });
                uploadRef.current.showDialog();
              }}
            />
          </Row>
          <Input02
            height={35}
            value={product?.artboardUrl || ""}
            onChange={(v) => updateProduct({ artboardUrl: v })}
            mb1
            inputProps={{ editable: canEdit }}
          />
        </Col>
        {/* {renderCommonListingInfo()} */}
        <Col>
          <ProductImages
            product={product}
            updateProduct={updateProduct}
            backgroundColor={"#E6E6E6"}
          />
        </Col>
      </Grid>
    );
  };

  const renderUnitSelection = () => {
    return null;
    // const colors = {
    //   mm: {
    //     bg: unit === 'mm' ? COLOR.MAIN : COLOR.GREY_LIGHT,
    //     text: unit === 'mm' ? 'white' : undefined,
    //   },
    //   inch: {
    //     bg: unit === 'inch' ? COLOR.MAIN : COLOR.GREY_LIGHT,
    //     text: unit === 'inch' ? 'white' : undefined,
    //   }
    // }
    // return (
    //   <Row ml1>
    //     <Col round1 p0 backgroundColor={colors.mm.bg} onPress={() => setUnit('mm')}>
    //       <Text caption color={colors.mm.text}>mm</Text>
    //     </Col>
    //     <Col round1 p0 ml1 backgroundColor={colors.inch.bg} onPress={() => setUnit('inch')}>
    //       <Text caption color={colors.inch.text}>inch</Text>
    //     </Col>
    //   </Row>
    // );
  };

  const renderTabPrint = () => {
    return (
      <Grid xs='100%' md='100%' lg='100%' xl='50%' alignItems="stretch">
        <Col p2>
          {renderTab()}
          <Text caption mb1>
            * Print Info is the technical information for designing and
            printing.
          </Text>

          <Row mb1>
            <Text subtitle1>Editor Size</Text>
          </Row>

          <Text caption mb1>
            * Editor width & height will be the size of the input image
            generated from the editor.
          </Text>
          <Row>
            <Col flex1 mr1>
              <Text subtitle1 mb1>
                Editor Width {`(${unit})`}
              </Text>
              <Input02
                height={35}
                value={product?.editorWidth || ""}
                onChange={(v) => updateProduct({ editorWidth: v })}
                mb1
                onBlur={validateNumberInput("physicalWidthIn")}
                inputProps={{ editable: canEdit }}
              />
            </Col>
            <Col flex1>
              <Text subtitle1 mb1>
                Editor Height {`(${unit})`}
              </Text>
              <Input02
                height={35}
                value={product?.editorHeight || ""}
                onChange={(v) => updateProduct({ editorHeight: v })}
                mb1
                onBlur={validateNumberInput("physicalHeightIn")}
                inputProps={{ editable: canEdit }}
              />
            </Col>
          </Row>

          <Row mb1>
            <Text subtitle1>Print Size</Text>
            {renderUnitSelection()}
          </Row>

          <Text caption mb1>
            * Print width & height will be the size of the output PDF document.
          </Text>
          <Row>
            <Col flex1 mr1>
              <Text subtitle1 mb1>
                Width {`(${unit})`}
              </Text>
              <Input02
                height={35}
                value={product?.physicalWidth || ""}
                onChange={(v) => updateProduct({ physicalWidth: v })}
                mb1
                onBlur={validateNumberInput("physicalWidthIn")}
                inputProps={{ editable: canEdit }}
              />
            </Col>



            <Col flex1>
              <Text subtitle1 mb1>
                Height {`(${unit})`}
              </Text>
              <Input02
                height={35}
                value={product?.physicalHeight || ""}
                onChange={(v) => updateProduct({ physicalHeight: v })}
                mb1
                onBlur={validateNumberInput("physicalHeightIn")}
                inputProps={{ editable: canEdit }}
              />
            </Col>
          </Row>

          <Col>
            <Row mb1>
              <Text subtitle1>DPI </Text>
            </Row>
            {recommendedDPI > 0 && Number(product?.dpi) !== recommendedDPI && (
              <Text caption mb1>
                * We recommend using DPI {recommendedDPI} to have perfect size. Check <a target="_blank" href="https://docs.google.com/spreadsheets/d/1gj2E0bb9UMh5EaphA8AFtn9IzTnQrOYr8QSz8o_07Xo/edit?gid=79822449#gid=79822449">this document</a> for more info
              </Text>
            )}
            <Input02
              height={35}
              value={product?.dpi || ""}
              onChange={(v) => updateProduct({ dpi: v })}
              mb1
              inputProps={{ editable: canEdit }}
            />
          </Col>

          {product?.physicalHeight && product?.physicalWidth && (
            <>
              {canEdit ? (
                <Button
                  outline
                  text="Add print area"
                  mb1
                  height={30}
                  borderRadius={4}
                  borderWidth={1}
                  bgHovered={COLOR.GREY_LIGHT}
                  onPress={() => {
                    const areas = (product?.printAreas || []).slice();
                    const height = Number(
                      (product?.physicalHeight / 3).toFixed(2)
                    );
                    areas.push({
                      width: product?.physicalWidth || 0,
                      height,
                      top: product?.physicalHeight - height,
                      left: 0,
                    });
                    updateProduct({
                      printAreas: areas,
                    });
                  }}
                />
              ) : (
                <Text subtitle1 mb1>
                  Print Areas
                </Text>
              )}
            </>
          )}

          {(product?.printAreas || []).map((val, i) => {
            return (
              <Col key={"print-area-" + i}>
                <Row alignItems={"flex-end"}>
                  <Col flex1 margin={2.5}>
                    <Text caption mb0 numberOfLines={1}>
                      Width ({unit})
                    </Text>
                    <Input02
                      value={val.width}
                      onChange={onChangePrintArea("width", i)}
                      onBlur={onBlurPrintArea("width", i)}
                      onFocus={() => setFocusedArea(i)}
                      inputProps={{ editable: canEdit }}
                    />
                  </Col>
                  <Col flex1 margin={2.5}>
                    <Text caption mb0 numberOfLines={1}>
                      Height ({unit})
                    </Text>
                    <Input02
                      value={val.height}
                      onChange={onChangePrintArea("height", i)}
                      onBlur={onBlurPrintArea("height", i)}
                      onFocus={() => setFocusedArea(i)}
                      inputProps={{ editable: canEdit }}
                    />
                  </Col>
                  <Col flex1 margin={2.5}>
                    <Text caption mb0 numberOfLines={1}>
                      Top ({unit})
                    </Text>
                    <Input02
                      value={val.top}
                      onChange={onChangePrintArea("top", i)}
                      onBlur={onBlurPrintArea("top", i)}
                      onFocus={() => setFocusedArea(i)}
                      inputProps={{ editable: canEdit }}
                    />
                  </Col>
                  <Col flex1 margin={2.5}>
                    <Text caption mb0 numberOfLines={1}>
                      Left ({unit})
                    </Text>
                    <Input02
                      value={val.left}
                      onChange={onChangePrintArea("left", i)}
                      onBlur={onBlurPrintArea("left", i)}
                      onFocus={() => setFocusedArea(i)}
                      inputProps={{ editable: canEdit }}
                    />
                  </Col>
                  <Col width={40}>
                    <TouchField
                      cirle
                      middle
                      onPress={() => {
                        const areas = (product?.printAreas || []).slice();
                        updateProduct({
                          printAreas: [
                            ...areas.slice(0, i),
                            ...areas.slice(i + 1, areas.length),
                          ],
                        });
                      }}
                    >
                      <EvilIcons name="trash" size={24} color={COLOR.FONT} />
                    </TouchField>
                  </Col>
                </Row>
              </Col>
            );
          })}

          {canEdit ? (
            <Button
              outline
              text="Add SKU PDF"
              mb1
              mt2
              height={30}
              borderRadius={4}
              borderWidth={1}
              bgHovered={COLOR.GREY_LIGHT}
              onPress={() => {
                const skuPDFs = (product?.data?.skuPDFs || []).slice();
                skuPDFs.push({
                  sku: "",
                  url: "",
                });
                updateProductData({
                  skuPDFs,
                });
              }}
            />
          ) : (
            <Text subtitle1 mb1>
              SKU PDF
            </Text>
          )}
          <Text caption mb1>
            * Some products do not need personalisation. Define SKU PDF here
            will help the system to skip PDF making process and use the PDF link
            instead
          </Text>

          {(product?.data?.skuPDFs || []).map((val, i) => {
            return (
              <Col key={"sku-pdf-" + i}>
                <Row alignItems={"flex-end"}>
                  <Col flex1 margin={2.5}>
                    <Text caption mb0 numberOfLines={1}>
                      SKU
                    </Text>
                    <Input02
                      value={val.sku}
                      onChange={onChangePDFSKU("sku", i)}
                      inputProps={{ editable: canEdit }}
                    />
                  </Col>
                  <Col flex1 margin={2.5}>
                    <Text caption mb0 numberOfLines={1}>
                      PDF
                    </Text>
                    <Input02
                      value={val.url}
                      onChange={onChangePDFSKU("url", i)}
                      inputProps={{ editable: canEdit }}
                    />
                  </Col>
                  <Col width={40}>
                    <TouchField
                      cirle
                      middle
                      onPress={() => {
                        const skuPDFs = (product?.data?.skuPDFs || []).slice();
                        updateProductData({
                          skuPDFs: [
                            ...skuPDFs.slice(0, i),
                            ...skuPDFs.slice(i + 1, skuPDFs.length),
                          ],
                        });
                      }}
                    >
                      <EvilIcons name="trash" size={24} color={COLOR.FONT} />
                    </TouchField>
                  </Col>
                </Row>
              </Col>
            );
          })}

          <Row mv1 alignSelf="flex-start">
            <Text subtitle1 mb0>
              Droplet URL
            </Text>

            <Button
              isLoading={imageLoading && imageId === "droplet"}
              outline
              ml1
              bgHovered={COLOR.GREY_BG}
              text="Upload droplet file"
              height={30}
              borderRadius={15}
              width={160}
              onPress={() => {
                setImageId("droplet");
                setImageUI({ loading: true });
                uploadRef.current.showDialog();
              }}
            />
          </Row>
          <Text caption mb1>
            Use a custom droplet file for this specific print size.{" "}
            <Text bold>{product?.physicalWidth}</Text> x{" "}
            <Text bold>{product?.physicalHeight}</Text>
          </Text>
          <Input02
            height={35}
            value={product?.dropletUrl || ""}
            onChange={(v) => updateProduct({ dropletUrl: v })}
            mb1
            inputProps={{ editable: canEdit }}
          />

          <Text caption mb0 mt1>
            Printer Identificator code
          </Text>
          <Input02
            height={35}
            value={product?.printerIdentificatorCode || ""}
            onChange={(v) => updateProduct({ printerIdentificatorCode: v })}
            mb1
            inputProps={{ editable: canEdit }}
          />
        </Col>
        <Col flex1>
          <PrintAreas
            product={product}
            physicalRatio={physicalRatio}
            focusedArea={focusedArea}
          />
        </Col>
        {/* <RightColumn flex={1} middle>
          {canEdit && (
            <>
              <Button
                isLoading={imageLoading && imageId === 'blueprint'}
                outline
                bgHovered={COLOR.GREY_BG}
                text='Blueprint Image'
                height={30}
                borderRadius={15}
                width={150}
                iconLeft={
                  <Entypo name="upload" size={20} color="black" />
                }
                mb2
                mh0
                onPress={() => {
                  setImageId('blueprint');
                  setImageUI({ loading: true });
                  uploadRef.current.showDialog();
                }}
              />
              <Text caption ph2>* Blueprint Image should be a blueprint, or an flat-front image of bottle, without any extra space. Using other type of image will result in miscalculation</Text>
            </>
          )}

          
        </RightColumn> */}
      </Grid>
    );
  };

  // const activateText = (() => {
  //   if (!product?.customProduct && !product?.wholeSale && !product?.printOnDemand) return 'Activate this product';
  //   const arr = [];
  //   if (product?.wholeSale) arr.push('Whole Sale');
  //   if (product?.printOnDemand) arr.push('Print on Demand');
  //   if (product?.customProduct) arr.push('Custom product');
  //   return arr.join(', ');
  // })();

  return (
    <CMSLayout requireAuthen
      breadcrumbs={[
        { title: `Product Catalogue`, screen: SCREEN.ListProducts },
        { title: product?.name || `Product details` },
      ]}
    >
      <Row m2 marginBottom={0} justifyContent={"space-between"}>
        <Text h3>{id === "new" ? "Create new product" : product?.name}</Text>
        {canEdit && (
          <Row>
            {/* <Button
              outline
              text={activateText}
              numberOfLineTitle={1}
              height={40}
              borderRadius={20}
              width={200}
              onPress={() => {
                showActivateModal(product);
              }}
              mr1
            /> */}
            {id !== "new" && (
              <Button
                mr1
                isLoading={removing}
                text="Delete"
                height={40}
                borderRadius={20}
                width={100}
                {...Button.colors.redSolid}
                onPress={async () => {
                  const result = await showPopupMessage({
                    title: "",
                    content: "Are you sure you want to delete this product?",
                    buttonOkText: "OK",
                    typeHighlight: "danger",
                    contentHighlight: "This action is not reversible.",
                  });
                  if (result && result.buttonOKClicked === true) {
                    setRemoveUI({ loading: true });
                    try {
                      const res = await Store.Api.Product.remove({ id });
                      if (res.data.error) {
                        showPopupMessage({
                          title: "",
                          content: String(res.data.error),
                          buttonOkText: "OK",

                          typeHighlight: "danger",
                          contentHighlight: "Error",
                        });
                        // alert(res.data.error);
                      } else {
                        updateEditting(screenName, false);
                        navigation.navigate(SCREEN.ListProducts);
                      }
                    } catch (err) {
                      showPopupMessage({
                        title: "",
                        content: String(err),
                        buttonOkText: "OK",

                        typeHighlight: "danger",
                        contentHighlight: "Error",
                      });
                      // alert(String(err));
                    }
                    setRemoveUI({ loading: false });

                  }
                  // showPopupMessage({
                  //   title: "",
                  //   content: "Are you sure you want to delete this product?",
                  //   buttonOkText: "OK",
                  //   onButtonOkClick: async () => {
                  //     setRemoveUI({ loading: true });
                  //     try {
                  //       const res = await Store.Api.Product.remove({ id });
                  //       if (res.data.error) {
                  //         showPopupMessage({
                  //           title: "",
                  //           content: String(res.data.error),
                  //           buttonOkText: "OK",

                  //           typeHighlight: "danger",
                  //           contentHighlight: "Error",
                  //         });
                  //         // alert(res.data.error);
                  //       } else {
                  //         updateEditting(screenName, false);
                  //         navigation.navigate(SCREEN.ListProducts);
                  //       }
                  //     } catch (err) {
                  //       showPopupMessage({
                  //         title: "",
                  //         content: String(err),
                  //         buttonOkText: "OK",

                  //         typeHighlight: "danger",
                  //         contentHighlight: "Error",
                  //       });
                  //       // alert(String(err));
                  //     }
                  //     setRemoveUI({ loading: false });
                  //   },
                  // typeHighlight: "danger",
                  //   contentHighlight: "This action is not reversible.",
                  // });
                  // const shouldRemove =
                  //  confirm('Are you sure you want to delete this product?');
                }}
              />
            )}
            <Button
              isLoading={submitting}
              text={id === "new" ? "Create" : "Save"}
              width={100}
              height={40}
              borderRadius={20}
              backgroundColor={
                !hasDoneSomeEditing ? "rgba(0,0,0,0.3)" : COLOR.MAIN
              }
              onPress={
                !hasDoneSomeEditing
                  ? undefined
                  : async () => {
                    if (!product) return;
                    if (!product.image) {
                      showPopupMessage({
                        title: "",
                        content: "Please upload main image.",
                        buttonOkText: "OK",

                        typeHighlight: "danger",
                        contentHighlight: "Error",
                      });
                      // alert('Please upload main image');
                      return;
                    }
                    setSubmitUI({ loading: true });
                    const availableForResellerIds = {};
                    selectedResellers.forEach(({ value }) => {
                      availableForResellerIds[value] = true;
                    });
                    console.log("product.data", product.data);
                    const res = await Store.Api.Product.upsert({
                      id: product.id,
                      name: product.name,
                      description: product.description,
                      label: product.label,
                      secondLabel: product.secondLabel,
                      estimatedLeadTime: product.estimatedLeadTime,
                      volume: product.volume,
                      material: product.material,
                      image: product.image,
                      banner: product.banner || '',
                      price: +product.price,
                      originalPrice: +product.originalPrice,
                      galleries: product.galleries?.filter(Boolean),
                      packagingDescription: product.packagingDescription,
                      artboardUrl: product.artboardUrl,
                      // packagingImage: product.packagingImage,
                      physicalWidth: product.physicalWidth,
                      physicalHeight: product.physicalHeight,
                      editorHeight: product.editorHeight,
                      editorWidth: product.editorWidth,
                      productHeight: product.productHeight,
                      productWidth: product.productWidth,
                      printAreas: product.printAreas,
                      availableForResellerIds,
                      tags: product.tags,
                      data: {
                        ...product.data,
                      },
                      previewData: product.previewData,
                      variations: variantOptions.map((v) => {
                        v.prices = v.prices.map((vP) => {
                          vP.amount = Number(vP.amount);
                          vP.price = Number(vP.price);
                          return vP;
                        });
                        return v;
                      }),
                      wholeSale: !!product.wholeSale,
                      printOnDemand: !!product.printOnDemand,
                      customProduct: !!product.customProduct,
                      dropletUrl: product.dropletUrl,
                      printerIdentificatorCode:
                        product.printerIdentificatorCode,
                      dpi: product?.dpi,
                      packPrices: product?.packPrices,
                    });
                    if (res.data.error) {
                      showPopupMessage({
                        title: "",
                        content: String(res.data.error),
                        buttonOkText: "OK",

                        typeHighlight: "danger",
                        contentHighlight: "Error",
                      });
                      // alert(res.data.error)
                    } else if (res.data.data.id) {
                      updateEditting(screenName, false);
                      navigation.navigate(SCREEN.UpsertProduct, {
                        id: res.data.data.id,
                      });
                      if (product.id) {
                        showPopupMessage({
                          title: "",
                          content: "Save product successfully.",
                          buttonOkText: "OK",

                          typeHighlight: "success",
                          contentHighlight: "Success",
                        });
                        // alert('Save product successfully');
                      }
                    }
                    setSubmitUI({ loading: false });
                  }
              }
            />
          </Row>
        )}
      </Row>
      <Col flex1 m2 mv1 round1 bgWhite borderWidth={1} borderColor={"white"}>
        {uiState.errorMes ? (
          <Col flex1 middle>
            <Text color="red" subtitle1>
              {uiState.errorMes}
            </Text>
          </Col>
        ) : uiState.fetching ? (
          <Row height={50} stretch>
            <ShimmerLoading round1 flex={1} m1 />
            <ShimmerLoading round1 flex={1} m1 />
            <ShimmerLoading round1 flex={1} m1 />
            <ShimmerLoading round1 flex={1} m1 />
          </Row>
        ) : (
          renderReady()
        )}
      </Col>
      <UploadFile ref={uploadRef} onUploaded={onImageUploaded} />
    </CMSLayout>
  );
};

UpsertProduct.routeInfo = {
  title: "Product - Bottled Goose",
  path: "/product/:id",
};

export default UpsertProduct;
