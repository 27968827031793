'use client'
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from 'react';
import { Modal } from 'antd';
import { Survey } from 'survey-react-ui';
import { Model } from 'survey-core';
import { useUserStore } from 'store/User.Store';
import apiClient from 'store/api-client';
import { FORM_TYPE } from 'const/survey';

const QuestionnaireModal = (props: any, ref) => {
  const [show, setShow] = useState(false);
  const [survey, setSurvey] = useState<Model | null>(null);
  const UserStore = useUserStore();

  useImperativeHandle(ref, () => ({
    show: () => {
      setShow(true);
    },
  }));

  const surveyComplete = useCallback(async (survey: Model) => {
    apiClient.Api.Survey.submitAnwser({
      type: FORM_TYPE.INITIAL_QUESTION,
      data: survey.data,
    })
    setTimeout(() => {
      setShow(false);
    }, 1000);
  }, []);

  if (survey) {
    survey.onComplete.add(surveyComplete);
  }

  useEffect(() => {
    UserStore.onReady().then(async (u) => {
      if (u?.role && u?.role !== 'admin') {
        const res = await apiClient.Api.Survey.getSurveyForm({
          type: FORM_TYPE.INITIAL_QUESTION,
        })
        if (res.data.data.data && !res.data.anwser) {
          setSurvey(new Model(res.data.data.data));
          setShow(true);
        }
      }
    })
  }, [])

  return (
    <Modal
      open={show}
      title={""}
      width={800}
      onOk={() => setShow(false)}
      onCancel={() => setShow(false)}
      footer={false}
      closable={false}
      maskClosable={false}
    >
      <Survey model={survey} />
    </Modal>
  );
};

export default forwardRef(QuestionnaireModal);
