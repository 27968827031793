export const SCREEN = {
  Login: 'Login',
  LoginMobile: 'LoginMobile',
  RegisterMobile: 'RegisterMobile',
  MessageMobile:'MessageMobile',
  Home: 'Home',
  HomeGetStarted: 'HomeGetStarted',
  SampleEditor: 'SampleEditor',
  SampleEditorTheChild: 'SampleEditorTheChild',
  ListPrintJobs: 'ListPrintJobs',
  Reports: 'Reports',
  PrintHistory: 'PrintHistory',
  ListProducts: 'ListProducts',
  UpsertProduct: 'UpsertProduct',
  ListDesigns: 'ListDesigns',
  UpsertDesign: 'UpsertDesign',
  ListResellers: 'ListResellers',
  UpsertReseller: 'UpsertReseller',
  ListInstances: 'ListInstances',
  UpsertInstance: 'UpsertInstance',
  ListStores: 'ListStores',
  UpsertStore: 'UpsertStore',
  ResellerPrintJobs: 'ResellerPrintJobs',
  ResellerRegister: 'ResellerRegister',
  BGService: 'BGService',
  Settings: 'Settings',
  UpsertUsers: 'UpsertUsers',
  ListOrders: 'ListOrders',
  ListOrderForReseller: 'ListOrderForReseller',
  ListDessignsReseller: 'ListDessignsReseller',
  OrderSearchResult: 'OrderSearchResult',
  OrderDetail: 'OrderDetail',
  ProductLibraryResellerDetail: 'ProductLibraryResellerDetail',
  ListOrderSampleRequest: 'ListOrderSampleRequest',
  ResellerCreateMyOwnProduct: 'ResellerCreateMyOwnProduct',
  ResellerEditDesign: 'ResellerEditDesign',
  ListWithdrawRequest: 'ListWithdrawRequest',
  MyWallet: 'MyWallet',
  DeletedOrders: 'DeletedOrders',
  ForgotPassword: 'ForgotPassword',
  PackingSlipDetail: 'PackingSlipDetail',
  ClaimMyStore: 'ClaimMyStore',
  ManualInvoice: 'ManualInvoice',
  ManualCreateInvoiceCreate: 'ManualCreateInvoice',
  OrderLogs: 'OrderLogs',
  Survey: 'Survey',
};
