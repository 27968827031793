import React, { useContext, useEffect, useRef, useState } from "react";
import { createContext, memo, useMemo } from "react";
import { useUserStore } from "store/User.Store";

interface SettingsFormValues {
  checkIsChanged?: (type?: "survey" | string) => boolean,
  onSaved?: (type?: "survey" | string) => void,
}

export const SettingsFormContext = createContext<SettingsFormValues>({
});

export const SettingsFormProvider = memo((props: any) => {
  const { children } = props;
  const { user } = useUserStore();
  const surveyChanged = useRef(false);
  const [survey, setSurvey] = useState({});

  useEffect(() => {
    // surveyChanged.current = false;
  }, []);

  const checkIsChanged = (type?: string) => {
    if (type === "survey") return surveyChanged.current;
    return surveyChanged.current;
  }

  const onSaved = (type: string) => {
    if (type === "survey") return surveyChanged.current = false;
  }

  const contextValue = useMemo(() => ({
    survey,
    setSurvey,
    checkIsChanged,
    onSaved,
  }), [
    survey,
    setSurvey,
    checkIsChanged,
    onSaved,
  ]);

  return (
    <SettingsFormContext.Provider value={contextValue}>
      {children}
    </SettingsFormContext.Provider>
  );
});

export const useSettingsForm = () => {
  return useContext(SettingsFormContext);
}
