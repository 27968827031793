import { initializeApp } from 'firebase/app';
import { getAuth, GoogleAuthProvider, FacebookAuthProvider } from 'firebase/auth';
const firebaseConfig = {
  apiKey: "AIzaSyCkvgkjGW8AzaqPhcMJIaZ6tQkf4sYSb2c",
  authDomain: "bottled-goose.firebaseapp.com",
  projectId: "bottled-goose",
  storageBucket: "bottled-goose.firebasestorage.app",
  messagingSenderId: "1005369404980",
  appId: "1:1005369404980:web:e0e686f6c76393c6fea461",
};

const app = initializeApp(firebaseConfig);

const auth = getAuth(app);
const googleProvider = new GoogleAuthProvider();
const facebookProvider = new FacebookAuthProvider();

export { auth, googleProvider, facebookProvider };