import React, { useEffect, useMemo, useRef, useState } from "react";
import {
  CMSLayout,
  Col,
  Row,
  Text,
  Button,
  useUIState,
  Grid,
  showPopupMessage,
  Select,
} from "components";
import { useNavFunc } from "navigation";
import { COLOR } from "const";
import { SettingsFormProvider } from "./FormContext";
import { useDynamicResponsiveValue } from "quickly-react";
import SurveySettings from "./Settings.Survey";
import SurveySettingsLib from "./Settings.Survey.lib";
import CloneMeSettings from "./Settings.CloneMe";
import SurveyAnwserSettings from "./Settings.SurveyAnwser";

const TABS = [
  // "Survey Editor (Test)",
  "Answers",
  "Form Editor",
];

const SurveyTabs = () => {
  const { navigation, route } = useNavFunc();
  const rV = useDynamicResponsiveValue(['xs', 'lg']);
  const isMobile = rV({ xs: true, lg: false });
  const { tabId }: any = route.params || {};
  const [currentTab, setCurrentTab] = useState<number>(0);
  const surveyRef = useRef<any>(null);

  const [{ loading: submitting }, setSubmitUI] = useUIState();

  useEffect(() => {
    const unsubscribe = navigation.addListener('focus', () => {
      loadData();
    });
    return unsubscribe;
  }, []);

  const loadData = () => {
    let tabIdx = 0;
    switch (tabId) {
      case "survey-answer":
        tabIdx = 0;
        break;
      case "survey":
        tabIdx = 1;
        surveyRef.current && surveyRef.current?.getData();
        break;
      // case "survey-test":
      //   tabIdx = 1;
      //   break;
      default:
        break;
    }
    setCurrentTab(tabIdx);
    onChangeTabs(tabIdx);
  }
  const submit = async () => {
    setSubmitUI({ loading: true });
    try {

      const res = await surveyRef.current?.updateSurvey();

      if (res.data.error) {
        showPopupMessage({
          title: '',
          content: String(res.data.error),
          buttonOkText: 'OK',

          typeHighlight: 'danger',
          contentHighlight: 'Error'

        });
        // alert(res.data.error);
      } else if (res.data.data) {
        showPopupMessage({
          title: '',
          content: 'Success',
          buttonOkText: 'OK',

          typeHighlight: 'success',
          contentHighlight: 'Success'

        });
      }
    } catch (err) {
      showPopupMessage({
        title: '',
        content: String(err),
        buttonOkText: 'OK',

        typeHighlight: 'danger',
        contentHighlight: 'Error'

      });
    }
    setSubmitUI({ loading: false });
  };

  const ButtonSave = React.memo(() => (
    <Button
      isLoading={submitting}
      text="Save"
      borderRadius={20}
      paddingVertical={6}
      paddingHorizontal={10}
      onPress={submit}
    />
  ));

  const checkChangedBeforeSwitch = () => new Promise((resolve) => {
    let currentType;
    switch (currentTab) {
      case 0:
        currentType = "survey-answer";
        break;
      case 1:
        currentType = "survey";
        break;
      // case 2:
      //   currentType = "survey-test";
      //   break;

      default:
        break;
    }
    if (!currentType) return resolve(true);
    // if (checkIsChanged(currentType)) {
    //   modalConfirm({
    //     title: 'Warning',
    //     content: 'Are you sure you want to exit without saving?',
    //     onOk: () => {
    //       resolve(true);
    //       onSaved(currentType);
    //     },
    //     onCancel: () => {
    //       resolve(false);
    //     },
    //   })
    // } else {
    //   resolve(true);
    // }
  })

  const onChangeTabs = async (index: number) => {
    // const canChange = await checkChangedBeforeSwitch();
    // if (!canChange) return;

    setCurrentTab(index);

    switch (index) {
      case 0:
        navigation.setParams({ tabId: 'survey-answer' });
        return;
      case 1:
        navigation.setParams({ tabId: 'survey' });
        return;
      // case 1:
      //   navigation.setParams({ tabId: 'survey-test' });
      //   return;
      default:
        return;
    }
  };

  const tabOptions = TABS.map((item, index) => ({ label: item, value: index }));

  const content = useMemo(() => {
    switch (currentTab) {
      case 0:
        return <SurveyAnwserSettings />;
      case 1:
        return <SurveySettings ref={surveyRef} />;
      // case 1:
      //   return <SurveySettingsLib />;
      default:
        return <CloneMeSettings />;
    }
  }, [currentTab]);

  const button = useMemo(() => {
    switch (currentTab) {
      case 1:
        return <ButtonSave />;
      default:
        return null;
    }
  }, [currentTab]);

  return (
    <CMSLayout requireAuthen>
      <Row m2 marginBottom={0} justifyContent={"space-between"}>
        <Row>
          <Text h3 marginRight={12}>
            Survey
          </Text>
          <>
            {!isMobile ? (
              TABS.map((item, index) => (
                <Col
                  onPress={() => onChangeTabs(index)}
                  marginLeft={8}
                  paddingHorizontal={12}
                  paddingVertical={6}
                  borderRadius={5}
                  backgroundColor={index === currentTab ? COLOR.MAIN : COLOR.GREY}
                >
                  <Text
                    fontSize={12}
                    color={index === currentTab ? COLOR.WHITE : COLOR.BLACK}
                  >
                    {item}
                  </Text>
                </Col>
              ))
            ) : null}
          </>
        </Row>
        <Row>{button}</Row>
      </Row>
      {isMobile && (
        <Col m2>
          <Select
            bgWhite noBorder
            round0
            options={tabOptions}
            value={tabOptions.find((v) => v.value === currentTab)}
            onChange={(data) => onChangeTabs(data.value)}
            placeholder="Select settings"
          />
        </Col>
      )}
      <Col flex1 m2 mv1 p1 round1 bgWhite overflow={"scroll"}>
        <Grid xs="100%" md="100%" alignItems={"flex-start"} mb2>
          <Col m1>{content}</Col>
        </Grid>
      </Col>
    </CMSLayout>
  );
};

const SurveyTabsWithContext = (props) => {
  return (
    <SettingsFormProvider>
      <SurveyTabs {...props} />
    </SettingsFormProvider>
  )
}

SurveyTabsWithContext.routeInfo = {
  title: "Survey",
  path: "/survey",
};

export default SurveyTabsWithContext;
